import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import Alert, { AlertsTypes } from 'components/Alert';
import {
	Button,
	createStyles,
	FormGroup,
	LinearProgress,
	makeStyles,
	TextField,
	Theme,
	Select,
	MenuItem,
	Box,
	Typography,
	FormControlLabel,
	Checkbox,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import CloseIcon from '@material-ui/icons/Close';

import { fetchUsers, updateUser, deleteUser, resetPassword, fetchTeachers } from 'lib/models/users';

import useLoggedUserDocument from 'hooks/useLoggedUserDocument';
import ConfirmationDialog from 'components/ConfirmationDialog';

import useLogout from 'hooks/useLogout';
import useSelectedLanguage from 'hooks/useSelectedLanguage';

import useUpdateLanguage from './hooks/useUpdateLanguage';
import useUserId from '../../hooks/useUserId';
import InputContainer from 'components/containers/InputContainer';
import { UserGrade, userGrades } from 'teachers-types';
import { getStringFromData } from 'lib/helpers';

type Props = {
	id: string;
	onEdition: (user: User | null, deleted?: boolean) => any;
	editingSelf: boolean;
	useDisableEdit: boolean;
	withPasswordReset: boolean;
	isModal: boolean;
	withDelete: boolean;
	type?: UserType[];
	withStatus: boolean;
	schoolHubspotId?: string;
};

function UserEditionForm({
	id,
	onEdition,
	withDelete,
	editingSelf,
	useDisableEdit,
	withPasswordReset,
	isModal,
	type,
	withStatus,
	schoolHubspotId = '',
}: Props) {
	const { t } = useTranslation();

	const updateLanguage = useUpdateLanguage();

	const history = useHistory();

	const classes = useStyles();

	const newUser = !!id && id === 'new';

	const selectedLanguage = useSelectedLanguage();

	const logout = useLogout();

	const [loading, setLoading] = useState<boolean>(true);
	const [originalUser, setOriginalUser] = useState<User>({
		_id: '',
		first_name: '',
		last_name: '',
		Rules: {},
		status: 'isactive',
		type: !!type ? type[0] : 'user',
		username: '',
		schoolHubspotId,
	});
	const [user, setUser] = useState<User>({
		_id: '',
		first_name: '',
		last_name: '',
		Rules: {},
		status: 'isactive',
		type: !!type ? type[0] : 'user',
		username: '',
		schoolHubspotId,
	});
	const [error, setError] = useState<string>('');
	const [success, setSuccess] = useState<boolean>(false);
	const [disableEdit, setDisableEdit] = useState<boolean>(useDisableEdit ? true : false);
	const [confirmDeleteUser, setDeleteUser] = useState(false);
	const [confirmResetPassword, setResetPassword] = useState(false);
	const [language, setLanguage] = useState(selectedLanguage);
	const [showSavingConfirmation, setShowSavingConfirmation] = useState(false);

	const editingTeacher = type && (type.includes('teacher') || type.includes('teacherAdmin'));

	const userSession = useLoggedUserDocument();

	const { id: loggedUserId } = useUserId();
	const loggedUserType: UserType | '' = userSession?.type || '';
	const isAdmin = loggedUserType === 'admin';

	const hubspotKeysUpdated =
		originalUser.username !== user.username ||
		originalUser.email !== user.email ||
		originalUser.first_name !== user.first_name ||
		originalUser.last_name !== user.last_name ||
		originalUser.schoolHubspotId !== user.schoolHubspotId ||
		getStringFromData(originalUser.grades || []) !== getStringFromData(user.grades || []);

	const dbKeysUpdated = originalUser.status !== user.status || originalUser.type !== user.type;

	const canSave =
		user.email &&
		user.first_name &&
		user.last_name &&
		(!editingTeacher || user.schoolHubspotId) &&
		(hubspotKeysUpdated || dbKeysUpdated) &&
		!!schoolHubspotId;

	useEffect(() => {
		setLanguage(selectedLanguage);
	}, [selectedLanguage]);

	useEffect(() => {
		const init = async () => {
			if (id && id === 'new') {
				if (isAdmin) {
					setDisableEdit(false);
				}
				setLoading(false);
				return;
			}

			const response = await (editingTeacher ? fetchTeachers() : fetchUsers());

			const found = id || loggedUserId ? response.find((user) => user._id === (id || loggedUserId)) : null;

			if (found) {
				setUser(found);
				setOriginalUser(found);
				if (found._id === loggedUserId) {
					setDisableEdit(false);
				}
			} else if (!id) {
				setUser(userSession!);
				setOriginalUser(userSession!);
				setDisableEdit(false);
			} else if (isAdmin && useDisableEdit) {
				setDisableEdit(true);
			}

			setLoading(false);
		};

		init();
	}, [id, isAdmin, loggedUserId, loggedUserType, t, editingTeacher, useDisableEdit, userSession]);

	const onUpdate = (key: keyof User) => {
		return (event: any) => {
			const value = event.target.value;

			if (user) {
				setUser({ ...user, [key]: value });
			}
		};
	};

	const save = async () => {
		if (!showSavingConfirmation && editingTeacher && hubspotKeysUpdated) {
			setShowSavingConfirmation(true);
			return;
		}
		setShowSavingConfirmation(false);
		let response: User | null = null;
		try {
			setLoading(true);
			response = await updateUser(user);
			setSuccess(true);
			if (language) updateLanguage(language);
		} catch (error) {
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
		onEdition(response || user);
	};

	function toggleDeleteUser() {
		setDeleteUser(true);
	}

	function toggleResetPassword() {
		setResetPassword(true);
	}

	async function onDeleteConfirm(confirmed: boolean) {
		try {
			setDeleteUser(false);
			if (confirmed) {
				setLoading(true);
				await deleteUser(user);
				onEdition(user, true);
			}
		} catch (error) {
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
	}

	async function onResetConfirm(confirmed: boolean) {
		try {
			setResetPassword(false);
			if (confirmed) {
				setLoading(true);
				await resetPassword(user);
				if (!id) {
					logout();
				} else {
					history.push(isAdmin ? '/users' : '/');
				}
			}
		} catch (error) {
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
	}

	function toggleGrade(grade: UserGrade) {
		setUser((current) => {
			const currentGrades = current.grades || [];
			return {
				...current,
				grades: currentGrades.includes(grade)
					? currentGrades.filter((current) => current !== grade)
					: [...currentGrades, grade],
			};
		});
	}

	function toggleGrades(checked: boolean) {
		setUser(
			//@ts-ignore
			(current) => {
				return { ...current, grades: checked ? userGrades : [] };
			}
		);
	}

	const userCreated = !loading && success && newUser;

	return (
		<Box>
			{isModal && (
				<Box className={classes.closeButtonContainer}>
					<Button
						variant="contained"
						color="secondary"
						size="large"
						className={classes.closeButton}
						startIcon={<CloseIcon />}
						onClick={() => onEdition(null)}
					>
						{t('common:close')}
					</Button>
				</Box>
			)}

			{loading && <LinearProgress className={classes.input} />}

			<FormGroup>
				{!schoolHubspotId && (
					<Typography variant="caption" color="error">
						The school must exist before editing the user
					</Typography>
				)}
				{!editingTeacher && (
					<TextField
						id="username"
						label={t('common:username')}
						fullWidth={true}
						value={user.username}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('username')}
						disabled={id !== 'new'}
					/>
				)}
				<TextField
					id="first"
					label={t('common:firstName')}
					fullWidth={true}
					value={user.first_name}
					variant="outlined"
					className={classes.input}
					onChange={onUpdate('first_name')}
					disabled={loading || disableEdit}
				/>
				<TextField
					id="last"
					label={t('common:lastName')}
					fullWidth={true}
					value={user.last_name}
					variant="outlined"
					className={classes.input}
					onChange={onUpdate('last_name')}
					disabled={loading || disableEdit}
				/>
				<TextField
					id="email"
					label={t('common:email')}
					fullWidth={true}
					value={user.email || ''}
					variant="outlined"
					className={classes.input}
					onChange={onUpdate('email')}
					inputMode="email"
					type="email"
					disabled={loading || disableEdit}
				/>
				{isAdmin && !type && (
					<Select
						labelId="type"
						id="type"
						label={t('common:role')}
						fullWidth={true}
						value={user.type || 'user'}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('type')}
						disabled={loading || disableEdit}
					>
						<MenuItem value="admin">{t('common:role_admin')}</MenuItem>
						<MenuItem value="panel">{t('common:role_panel')}</MenuItem>
					</Select>
				)}
				{!!type && (
					<Select
						labelId="type"
						id="type"
						label={t('common:role')}
						fullWidth={true}
						value={user.type || 'user'}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('type')}
						disabled={loading || disableEdit}
					>
						{type.map((el) => {
							return (
								<MenuItem key={`type-${el}`} value={el}>
									{t(`common:role_${el}`)}
								</MenuItem>
							);
						})}
					</Select>
				)}
				{isAdmin && withStatus && (
					<Select
						labelId="status"
						id="status"
						label={t('common:status')}
						fullWidth={true}
						value={user.status || 'isactive'}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('status')}
						disabled={loading || disableEdit}
					>
						<MenuItem value="isactive">{t('common:active')}</MenuItem>
						<MenuItem value="inactive">{t('common:inactive')}</MenuItem>
					</Select>
				)}
				{!disableEdit && !newUser && editingSelf && (
					<Select
						labelId="language"
						id="language"
						label={t('common:language')}
						fullWidth={true}
						value={language || 'es'}
						variant="outlined"
						className={classes.input}
						onChange={(ev) => setLanguage(ev.target.value as SupportedLanguage)}
						disabled={loading}
					>
						<MenuItem value="es">{t('common:spanish')}</MenuItem>
						<MenuItem value="en">{t('common:english')}</MenuItem>
					</Select>
				)}

				{!!editingTeacher && (
					<InputContainer title={t('schools:level')}>
						<Box className={classes.flexContainer}>
							<FormControlLabel
								label={t('common:all')}
								control={
									<Checkbox
										checked={(user?.grades ?? []).length === userGrades.length}
										onChange={(_, checked) => toggleGrades(checked)}
									/>
								}
							/>
						</Box>
						{userGrades.map((userGrade) => {
							return (
								<Box className={classes.subTree} key={`level-${userGrade}`}>
									<FormControlLabel
										key={`grade-${userGrade}`}
										label={userGrade}
										control={
											<Checkbox
												checked={(user?.grades ?? []).includes(userGrade)}
												onChange={() => toggleGrade(userGrade)}
											/>
										}
									/>
								</Box>
							);
						})}
					</InputContainer>
				)}

				{!disableEdit && (
					<Button
						variant="contained"
						color="primary"
						size="large"
						className={classes.button}
						startIcon={<SaveIcon />}
						onClick={save}
						disabled={!canSave}
					>
						{t('common:save')}
					</Button>
				)}
				{withDelete && !disableEdit && user.username !== 'admin' && isAdmin && !newUser && !editingSelf && (
					<Button
						variant="contained"
						color="secondary"
						size="large"
						className={classes.button}
						startIcon={<DeleteIcon />}
						onClick={toggleDeleteUser}
					>
						{t('common:delete')}
					</Button>
				)}
				{withPasswordReset && !disableEdit && !newUser && (
					<Button
						variant="contained"
						color="default"
						size="large"
						className={classes.button}
						startIcon={<DeleteIcon />}
						onClick={toggleResetPassword}
					>
						{t('users:resetPassword')}
					</Button>
				)}
			</FormGroup>

			{showSavingConfirmation && (
				<ConfirmationDialog
					title={t('users:confirmTeachersSaveTitle')}
					description={t('users:confirmTeachersSaveDescription')}
					onClose={(confirmed) => (!confirmed ? setShowSavingConfirmation(false) : save())}
					loading={loading}
				/>
			)}

			{!userCreated && confirmDeleteUser && (
				<ConfirmationDialog
					title={t('users:deleteTitle')}
					description={t('common:deleteText')}
					onClose={onDeleteConfirm}
					loading={loading}
				/>
			)}

			{!userCreated && confirmResetPassword && (
				<ConfirmationDialog
					title={t('users:resetPasswordTitle')}
					description={t('users:resetPasswordText')}
					onClose={onResetConfirm}
					loading={loading}
				/>
			)}

			{!loading && success && (
				<Alert
					message={t('common:success')}
					show={success}
					type={AlertsTypes.success}
					onClose={() => !userCreated && setSuccess(false)}
				/>
			)}
			{!loading && error && (
				<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
			)}
		</Box>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		input: {
			marginBottom: theme.spacing(3),
		},
		large: {
			width: theme.spacing(10),
			height: theme.spacing(10),
			margin: theme.spacing(4),
		},
		button: {
			margin: theme.spacing(1),
		},
		closeButtonContainer: {
			display: 'flex',
			flexDirection: 'row',
			marginBottom: theme.spacing(1),
			justifyContent: 'flex-end',
		},
		flexContainer: {
			display: 'flex',
			flexDirection: 'row',
		},
		closeButton: {},
		text: {
			marginTop: theme.spacing(2),
		},
		subTree: {
			display: 'inline-block',
		},
	})
);

export default UserEditionForm;
