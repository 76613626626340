import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory, useParams } from 'react-router-dom';

import Alert, { AlertsTypes } from 'components/Alert';
import {
	Box,
	Button,
	createStyles,
	FormControl,
	FormGroup,
	InputLabel,
	LinearProgress,
	makeStyles,
	MenuItem,
	Modal,
	Select,
	TextField,
	Theme,
	Typography,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/AddRounded';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';
import ConfirmationDialog from 'components/ConfirmationDialog';
import ItemTable from 'components/ItemTable';
import InputContainer from 'components/containers/InputContainer';

import { deleteList, fetchList, updateList } from 'lib/models/lists';
import { getStringFromData } from 'lib/helpers';

import useItems from 'hooks/useItems';
import useIsAdmin from 'hooks/useIsAdmin';
import useSchools from 'hooks/useSchools';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import { Autocomplete } from '@material-ui/lab';
import { levelsFilters } from 'types/constants';
import { listStatus } from 'teachers-types';

function ListEdition() {
	const { t } = useTranslation();
	const isAdmin = useIsAdmin();
	const history = useHistory();
	const classes = useStyles();
	const { id } = useParams<{ id: string }>();

	const newList = !id || id === 'new';

	const title = newList ? t('lists:createList') : t('lists:editList');

	const [showSearchModal, setShowSearchModal] = useState(false);

	const [loading, setLoading] = useState<boolean>(true);
	const [list, setList] = useState<List>({
		_id: '',
		level: 'Inicial|Sala de 2',
		name: '',
		items: [],
		status: 'draft',
		username: '',
		author: {} as User,
		createdAt: '',
		updatedAt: '',
	});
	const [originalList, setOriginalList] = useState<List>({
		_id: '',
		level: 'Inicial|Sala de 2',
		name: '',
		items: [],
		status: 'draft',
		username: '',
		author: {} as User,
		createdAt: '',
		updatedAt: '',
	});
	const [error, setError] = useState<string>('');
	const [success, setSuccess] = useState<boolean>(false);
	const [confirmDeleteList, setDeleteList] = useState(false);

	const itemsState = useItems();
	const schoolsState = useSchools();

	const listCreated = !loading && success && newList;

	const isDisabled = !isListUpdated(originalList, list) || !validForm(list);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchList(id);
		if (response) {
			setList(response);
			setOriginalList(response);
		}
		setLoading(false);
	}, [id]);

	useEffect(() => {
		const init = async () => {
			if (!id || id === 'new') {
				setLoading(false);
				return;
			}
			refetch();
		};
		init();
	}, [id, refetch]);

	const onUpdate = (key: keyof List) => {
		return (event: any) => {
			const value = event.target.value;

			if (list) {
				setList({ ...list, [key]: value });
			}
		};
	};

	const save = async () => {
		try {
			setLoading(true);
			const updatedList = await updateList(list);
			const found = Array.isArray(updatedList)
				? updatedList.find((current) => list.name === current.name)
				: updatedList;
			if (found) {
				setList(found);
			}
			setSuccess(!!found);
			history.goBack();
		} catch (error) {
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
	};

	function toggleDeleteList() {
		setDeleteList(true);
	}

	async function onDeleteConfirm(confirmed: boolean) {
		try {
			setDeleteList(false);
			if (confirmed) {
				setLoading(true);
				await deleteList(list);
				history.goBack();
			}
		} catch (error) {
			setError(error && (error as any).message ? (error as any).message : error);
		}
		setLoading(false);
	}

	async function toggleItem(item: Item) {
		setList((current) => {
			const newItems = current.items.filter((currentItem) => currentItem._id !== item._id);
			const items = newItems.length !== current.items.length ? newItems : [...current.items, item];
			return { ...current, items };
		});
	}

	const itemsIds = list.items.map((item) => item._id);

	const school = useMemo(() => {
		return list.schoolHubspotId ? schoolsState.schools.find((el) => el.hubspotId === list.schoolHubspotId) : undefined;
	}, [schoolsState.schools, list.schoolHubspotId]);

	return (
		<ScreenContainer title={title}>
			<PaperContainer>
				{loading && <LinearProgress className={classes.input} />}

				<InputContainer
					title={
						<a href={`mailto: ${list.author?.email || ''}`} target="_blank" rel="noreferrer">
							{t('common:message', { email: list.author?.email || '' })}
						</a>
					}
				>
					<Typography variant="body1">{list.message || t('common:noMessage')}</Typography>
				</InputContainer>

				<FormGroup className={classes.form}>
					<TextField
						id="name"
						label={t('common:name')}
						fullWidth={true}
						value={list.name}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('name')}
					/>

					<TextField
						id="amount"
						label={t('lists:studentsAmount')}
						fullWidth={true}
						value={list.studentsAmount || 0}
						variant="outlined"
						className={classes.input}
						onChange={onUpdate('studentsAmount')}
						type="number"
					/>

					<FormControl fullWidth className={classes.input} variant="outlined">
						<InputLabel id={'status-label'}>{t('common:status')}</InputLabel>

						<Select
							labelId="status-label"
							id="status"
							value={list.status}
							label={t('common:status')}
							onChange={onUpdate('status')}
						>
							{listStatus.map((status) => {
								return (
									<MenuItem key={`status-${status}`} value={status}>
										{t(`common:${status}`)}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>

					<FormControl fullWidth className={classes.input} variant="outlined">
						<InputLabel id={'status-label'}>{t('common:level')}</InputLabel>

						<Select
							labelId="level-label"
							id="level"
							value={list.level}
							label={t('common:level')}
							onChange={onUpdate('level')}
						>
							{levelsFilters.map((level) => {
								return (
									<MenuItem key={`level-${level}`} value={level}>
										{level}
									</MenuItem>
								);
							})}
						</Select>
					</FormControl>

					{!!school && (
						<InputContainer title={`${t('common:school')}`} fullWidth={true}>
							<Box className={classes.schoolContainer}>
								<Typography title={school.name || t('common:loading')} variant="body1" className={classes.schoolText}>
									{school.name || ''}
								</Typography>
								<CustomTooltip
									aria-label={t('common:remove')}
									icon={() => <DeleteIcon color={school.hubspotId ? 'primary' : 'disabled'} />}
									title={t('common:remove')}
									onClick={() => setList((current) => ({ ...current, schoolHubspotId: undefined }))}
								/>
							</Box>
						</InputContainer>
					)}

					{!school && (
						<Box className={classes.schoolTable}>
							<Autocomplete
								fullWidth={true}
								onChange={(_, value) => setList((current) => ({ ...current, schoolHubspotId: value?.hubspotId }))}
								id="combo-box-schools"
								options={schoolsState.schools}
								getOptionLabel={(school: School) => school.name}
								renderInput={(params) => <TextField {...params} label={t('common:school')} variant="outlined" />}
								loading={schoolsState.loading}
							/>
						</Box>
					)}

					<InputContainer className={classes.tablesContainer} title={t('lists:items')}>
						<Box className={classes.rowContainer}>
							<Box />

							<AddIcon className={classes.addIcon} onClick={() => setShowSearchModal(true)} />
						</Box>
						<Box className={classes.tableContainer}>
							<ItemTable
								count={list.items.length}
								items={list.items}
								refetch={async (_: any) => {}}
								loading={false}
								columns={['kelCode', 'author', 'title']}
								rowAction={{
									callback: toggleItem,
									Icon: DeleteIcon,
									title: t('lists:onDeleteTitle'),
								}}
							/>

							<Modal open={showSearchModal} onClose={() => setShowSearchModal(false)}>
								<Box className={classes.tableModalContainer}>
									<ItemTable
										count={itemsState.count}
										items={itemsState.items.filter((currentItem) => !itemsIds.includes(currentItem._id))}
										refetch={itemsState.refetch}
										loading={false}
										rowAction={{
											callback: toggleItem,
											Icon: AddIcon,
											title: t('lists:onSelectedTitle'),
										}}
									/>
								</Box>
							</Modal>
						</Box>
					</InputContainer>

					<Button
						variant="contained"
						color="primary"
						size="large"
						className={classes.button}
						startIcon={<SaveIcon />}
						onClick={save}
						disabled={isDisabled}
						fullWidth={true}
					>
						{t('common:save')}
					</Button>
					{isAdmin && !newList && (
						<Button
							variant="contained"
							color="secondary"
							size="large"
							className={classes.button}
							startIcon={<DeleteIcon />}
							onClick={toggleDeleteList}
							fullWidth={true}
						>
							{t('common:delete')}
						</Button>
					)}
				</FormGroup>

				{!listCreated && confirmDeleteList && (
					<ConfirmationDialog
						title={t('lists:deleteTitle')}
						description={t('common:deleteText')}
						onClose={onDeleteConfirm}
						loading={loading}
					/>
				)}

				{!loading && success && (
					<Alert
						message={t('common:success')}
						show={success}
						type={AlertsTypes.success}
						onClose={() => !listCreated && setSuccess(false)}
					/>
				)}
				{!loading && error && (
					<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />
				)}
			</PaperContainer>
		</ScreenContainer>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		form: {
			display: 'flex',
			flexDirection: 'row',
			gap: theme.spacing(2),
		},
		input: {
			// flexGrow: 1,
			marginBottom: theme.spacing(2),
			maxWidth: '400px',
		},
		large: {
			width: theme.spacing(10),
			height: theme.spacing(10),
			margin: theme.spacing(4),
		},
		button: {
			marginTop: theme.spacing(1),
		},
		text: {
			marginTop: theme.spacing(2),
		},
		subTree: {
			display: 'inline-block',
		},
		createSchoolMessageContainer: {
			padding: theme.spacing(2),
		},
		editSchoolNameContainer: {
			marginBottom: theme.spacing(2),
		},
		editSchoolName: {
			color: theme.palette.text.hint,
		},
		extendedIcon: {
			position: 'absolute',
			right: theme.spacing(1),
			top: theme.spacing(1),
			'&:hover': {
				cursor: 'pointer',
			},
		},
		modal: {
			width: '100%',
			height: '100%',
			maxWidth: '1200px',
			margin: 'auto',
			marginTop: theme.spacing(2),
		},
		modalChild: {
			padding: theme.spacing(2),
		},
		customTooltip: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
		},
		tablesContainer: {
			display: 'flex',
			flexDirection: 'column',
			width: '100%',
		},
		tableContainer: {
			padding: theme.spacing(1),
		},
		tableModalContainer: {
			padding: theme.spacing(2),
		},
		rowContainer: {
			display: 'flex',
			flexDirection: 'row',
			width: '100%',
			justifyContent: 'space-between',
			paddingBottom: theme.spacing(1),
		},
		addIcon: {
			color: theme.palette.primary.main,
			cursor: 'pointer',
		},
		tables: {
			display: 'flex',
			flexDirection: 'row',
			gap: theme.spacing(4),
			flexWrap: 'wrap',
			width: '100%',
		},
		tableTitle: {
			paddingLeft: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		table: {
			width: '100%',
			[theme.breakpoints.up('lg')]: {
				maxWidth: '480px',
			},
			[theme.breakpoints.up('xl')]: {
				maxWidth: '700px',
			},
		},
		middle: {
			width: '5%',
		},
		schoolTable: {
			position: 'relative',
			width: '100%',
			marginTop: theme.spacing(-1),
		},
		schoolContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'space-between',
			alignItems: 'center',
			paddingRight: theme.spacing(1),
			paddingLeft: theme.spacing(1),
			gap: theme.spacing(1),
		},
		schoolText: {
			width: '100%',
		},
		message: {
			marginBottom: theme.spacing(2),
		},
	})
);

export default ListEdition;

function isListUpdated(originalList: List, currentList: List) {
	return getStringFromData(originalList) !== getStringFromData(currentList);
}

function validForm(list: List) {
	const valid = list.name.trim() !== '' && list.schoolHubspotId?.trim() !== '' && list.level?.trim() !== '';
	return valid;
}
