import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import LocalLibraryIcon from '@material-ui/icons/LocalLibrary';
import { MenuItem } from 'types/global';

type UsersProps = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
};

export default function Teachers({ selected, onSelect }: UsersProps) {
	const { t } = useTranslation();

	return (
		<ListItem
			button
			component={Link}
			to="/items"
			selected={selected === MenuItem.Items || selected === MenuItem.Item}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.Items)}
		>
			<ListItemIcon>
				<LocalLibraryIcon />
			</ListItemIcon>
			<ListItemText primary={t('general:item_plural')} />
		</ListItem>
	);
}
