import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

function useRules() {
	const user = useSelector(({ session: { user } }: RootState) => user);

	const rules = user?.Rules || ({} as ServerRules);

	return rules;
}

export default useRules;
