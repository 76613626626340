const initialState:DrawerState = {
	open: true,
	selectedItem: 0,
};

export type ToogleDrawerAction = {
	type: 'toogleDrawer';
};

export type HandleDrawerOpenAction = {
	type: 'handleDrawerOpen';
};

export type HandleDrawerCloseAction = {
	type: 'handleDrawerClose';
};

export type SetSelectedItemAction = {
	type: 'setSelectedItem';
	index: number;
}

type Actions = ToogleDrawerAction | HandleDrawerOpenAction | HandleDrawerCloseAction | SetSelectedItemAction;

const reducer = (state: DrawerState = initialState, action: Actions): DrawerState => {
	switch (action.type) {
		case 'toogleDrawer':
			return { ...state, open: !state.open };
		case 'handleDrawerOpen':
			return { ...state, open: true };
		case 'handleDrawerClose':
			return { ...state, open: false };
		case 'setSelectedItem':
			return { ...state, selectedItem: action.index}
		default:
			return state;
	}
};

export default reducer;
