import { createStyles, makeStyles } from '@material-ui/core/styles';

import { Fab } from '@material-ui/core';

import { APPBAR_MAX_HEIGHT } from 'config/constants';

type Props = {
	onClick: () => any;
	title: string;
	icon: React.ElementType;
};

function Float({ onClick, title, icon: Icon }: Props) {
	const classes = useStyles();

	return (
		<Fab
			className={classes.fab}
			size="medium"
			variant="extended"
			color="primary"
			aria-label="add"
			onClick={() => onClick()}
		>
			<Icon className={classes.extendedIcon} />
			{title}
		</Fab>
	);
}

export default Float;

const useStyles = makeStyles((theme) =>
	createStyles({
		fab: {
			position: 'fixed',
			[theme.breakpoints.up('sm')]: {
				top: `${APPBAR_MAX_HEIGHT - theme.spacing(2)}px`,
				marginLeft: theme.spacing(1),
			},
			[theme.breakpoints.down('sm')]: {
				bottom: theme.spacing(2),
				right: theme.spacing(2),
			},
			transition: theme.transitions.create(['left'], {
				easing: theme.transitions.easing.sharp,
				duration: theme.transitions.duration.leavingScreen,
			}),
			zIndex: theme.zIndex.drawer + 2,
		},
		extendedIcon: {
			marginRight: theme.spacing(1),
		},
	})
);
