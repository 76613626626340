import React from 'react';
import { createStyles, makeStyles, Paper } from '@material-ui/core';

function PaperContainer({ children, className }: React.PropsWithChildren<{ className?: string }>) {
	const classes = useStyles();

	return (
		<Paper elevation={1} className={className || classes.paper}>
			{children}
		</Paper>
	);
}

export default PaperContainer;

const useStyles = makeStyles((theme) =>
	createStyles({
		paper: {
			padding: theme.spacing(2),
		},
	})
);
