import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

function useLoggedUserDocument() {
	const loggedUser = useSelector(({ session: { user } }: RootState) => user);

	return loggedUser;
}

export default useLoggedUserDocument;
