function isTrue(data: any): data is true {
	return typeof data === 'boolean' && data === true;
}

function isSelf(data: SelfRule): data is SelfRule {
	return (
		!!data &&
		(typeof data === 'string' ||
			(typeof data === 'object' &&
				(typeof data.self === 'string' ||
					(typeof data.self === 'object' && typeof data.self.db === 'string' && typeof data.self.param === 'string'))))
	);
}

type ListTrue = {
	read: true;
	list: true;
};

type ReadTrue = {
	read: true;
};

type ReadSelf = {
	read: SelfRule;
};

type ListSelf = {
	read: SelfRule;
	list: SelfRule;
};

type Edit = {
	update: true;
	create: true;
};

type EditSelf = {
	update: SelfRule;
};

type Full = {
	read: true;
	list: true;
	update: true;
	create: true;
};

function read(self: boolean = true) {
	if (self) {
		return (data: ReadSelf): data is ListSelf => {
			return !!data && isSelf(data.read);
		};
	}
	return (data: any): data is ReadTrue => {
		return !!data && isTrue(data.read);
	};
}

function list(self: boolean = true) {
	if (self) {
		return (data: ListSelf): data is ListSelf => {
			return !!data && isSelf(data.list) && isSelf(data.read);
		};
	}
	return (data: any): data is ListTrue => {
		return !!data && isTrue(data.list) && isTrue(data.read);
	};
}

function edit(self: boolean = true) {
	if (self) {
		return (data: EditSelf): data is EditSelf => {
			return !!data && isSelf(data.update);
		};
	}
	return (data: any): data is Edit => {
		return !!data && isTrue(data.create) && isTrue(data.update);
	};
}

function full() {
	return (data: any): data is Full => {
		return !!data && isTrue(data.list) && isTrue(data.read) && isTrue(data.create) && isTrue(data.update);
	};
}

const _rules = {
	// User Profile
	Profile: {
		rules: [
			{
				name: 'Profile',
				validator: read(),
			},
			{
				name: 'Profile',
				validator: edit(),
			},
		],
	},
	UserReadOnly: {
		rules: [
			{
				name: 'User',
				validator: list(),
			},
		],
	},
	UserFull: {
		rules: [
			{
				name: 'User',
				validator: full(),
			},
		],
	},
	TeachersEdition: {
		rules: [
			{
				name: 'Teachers',
				validator: edit(false),
			},
		],
	},
	SchoolEdition: {
		rules: [
			{
				name: 'School',
				validator: edit(false),
			},
		],
	},
	ItemsFull: {
		rules: [
			{
				name: 'Item',
				validator: full(),
			},
		],
	},
	ListsFull: {
		rules: [
			{
				name: 'List',
				validator: full(),
			},
		],
	},
};

const rules: RulesDictionary = _rules;

export type RuleKey = keyof typeof _rules | '*';

export type RuleKeys = RuleKey | Array<RuleKey>;

export default rules;
