import { useTranslation } from 'react-i18next';

import { IconButton } from '@material-ui/core';

import FilterListIcon from '@material-ui/icons/Tune';
import CloseIcon from '@material-ui/icons/Close';
import TooltipButton from 'components/TooltipButton';

type Props = {
	collapsed: boolean;
	disabled?: boolean;
	toggleFilter: () => void | Promise<void>;
};

const ToggleFilterTooltip = ({ collapsed, disabled = false, toggleFilter }: Props) => {
	const { t } = useTranslation();

	return (
		<TooltipButton title={t('common:filter') as string}>
			<IconButton aria-label={t('common:filter')} disabled={disabled} onClick={() => toggleFilter()}>
				{collapsed && <FilterListIcon />}
				{!collapsed && <CloseIcon />}
			</IconButton>
		</TooltipButton>
	);
};

export default ToggleFilterTooltip;
