import { useParams } from 'react-router-dom';

import useLoggedUser from './useLoggedUser';

function useUserId() {
	const params = useParams<{ id?: string }>();
	const loggedUser = useLoggedUser();

	const fromParams = !!params && !!params.id;
	const id = fromParams ? params.id! : loggedUser ? loggedUser._id : null;

	return { id, fromParams };
}

export default useUserId;
