import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'store/Store';

function useDrawerActions() {
	const dispatch = useDispatch<AppDispatch>();

	const handleDrawerClose = useCallback(() => {
		dispatch({ type: 'handleDrawerClose' });
	}, [dispatch]);

	const handleDrawerOpen = useCallback(() => {
		dispatch({ type: 'handleDrawerOpen' });
	}, [dispatch]);

	const setSelectedItem = useCallback(
		(index: number) => {
			dispatch({ type: 'setSelectedItem', index });
		},
		[dispatch]
	);

	return { handleDrawerClose, handleDrawerOpen, setSelectedItem };
}

export default useDrawerActions;
