import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'store/Store';

function useSetServerDown() {
	const dispatch = useDispatch<AppDispatch>();

	const setExpired = useCallback(
		(isDown: boolean) => {
			dispatch({ type: 'serverDown', payload: isDown });
		},
		[dispatch]
	);

	return setExpired;
}

export default useSetServerDown;
