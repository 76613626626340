import React from 'react';

import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';

import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

type IconCardProps = {
	title: string;
	description: string;
	icon: React.ReactElement;
	primaryAction?: ((event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void) | undefined;
};

function IconCard({ title, description, icon, primaryAction }: IconCardProps) {
	const classes = useStyles();

	return (
		<Card className={classes.root}>
			<CardMedia className={classes.media}>{icon}</CardMedia>
			<CardActionArea onClick={primaryAction}>
				<CardContent>
					<Typography gutterBottom variant="h5" component="h2">
						{title}
					</Typography>
					<Typography variant="body2" color="textSecondary" component="p">
						{description}
					</Typography>
				</CardContent>
			</CardActionArea>
		</Card>
	);
}

export default IconCard;

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: 345,
			margin: theme.spacing(2),
		},
		media: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			backgroundColor: theme.palette.primary.main,
			padding: theme.spacing(4),
		},
	})
);
