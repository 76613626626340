const users = {
	notFound: 'User {{user}} not found',
	emptyImportFile: 'Empty file',
	deleteTitle: 'User deletion',
	deleteText: 'Delete selected user?',
	deleteText_plural: 'Delete selected users?',
	confirmImportTitle: 'Import confirmation',
	confirmImportDescription: '{{totalToImport}} of {{amountOfUsers}} users will be imported',
	importedDescription: '{{importedAmount}} users imported',
	assignedUser: 'User with document ({{documentNumber}})',
	assignedUsers: 'Assigned users ({{amount}})',
	unassignedUser: 'User with document ({{documentNumber}})',
	unassignedUsers: 'Unassigned users ({{amount}})',
	invalidUser: 'User in row ({{index}})',
	invalidUsers: 'Invalid users  ({{amount}})',
	importDescription:
		'Upload a single csv file, the system will automatically try to identify the document numbers. In the event that this is not possible, you will be asked to select one from the database.',
	importDescriptionWarning: 'All users to import that has the same document number will be replaced.',
	invalidFile: 'Invalid file, columns with name "firstName", "lastName", "document" and "email" are needed',
	invalidDocument: 'The document must have the format xx-xxxxxxxx-x',
	uploadUsersError: "This users weren't updated ({{users}})",
	createUser: 'Create user',
	resetPassword: 'Clear password',
	resetPasswordTitle: 'Clear password',
	resetPasswordText: 'Clear password of selected user?',
	creationSuccessTitle: 'User created',
	creationSuccessDescription:
		'The "{{first_name}} {{last_name}}" user was successfully created, now {{first_name}} can access the panel using the username "{{username}}" and a password of his choice.',
	creationPasswordText:
		'The first time the user logs in it will create his password which will be used in subsequent accesses.',
	confirmTeachersSaveTitle: 'Saving data in Hubspot',
	confirmTeachersSaveDescription:
		'This modification will also impact the Hubspot database, this process will consume part of your system access fee (Are you sure you want to continue?)',
};

export default users;
