import common from './common';
import general from './general';
import system from './system';
import users from './users';
import schools from './schools';
import items from './items';
import lists from './lists';

const en = {
	common,
	general,
	system,
	users,
	schools,
	items,
	lists,
} as const;

export default en;
