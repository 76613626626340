import { useEffect, useState } from 'react';

import {
	academicLevels,
	approaches,
	cefrLevels,
	certificates,
	components,
	curriculums,
	languages,
	levels,
	subjects,
	workloads,
} from 'teachers-types';

import { fetchSeries } from 'lib/models/items';

import { levelsFilters } from 'types/constants';

export type ParentValue = keyof typeof levels;

const valuesGetter: { [key in string]: (parentValue?: ParentValue) => Promise<string[]> } = {
	approach: async () => approaches as any as string[],
	certificate: async () => certificates as any as string[],
	component: async () => components as any as string[],
	curriculum: async () => curriculums as any as string[],
	isRecommended: async () => ['true', 'false'],
	isFeatured: async () => ['true', 'false'],
	language: async () => languages as any as string[],
	serie: async () => {
		const series = await fetchSeries();
		return series;
	},
	level: async () => levelsFilters as any as string[],
	subject: async () => subjects as any as string[],
	workload: async () => workloads as any as string[],
	academicLevel: async () => academicLevels as any as string[],
	cefrLevel: async () => cefrLevels as any as string[],
	grade: async (parent?: keyof typeof levels) =>
		(parent && levels[parent] && levels[parent].length ? levels[parent] : []) as unknown as string[],
	showroomType: async () => ['pdf', 'website'],
	text: async () => [] as string[],
} as const;

export default function useValues<T extends string>(key: T, parentValue?: ParentValue) {
	const [currentValues, setValues] = useState<string[]>([] as string[]);

	useEffect(() => {
		async function sync() {
			const data = valuesGetter[key] ? await valuesGetter[key]!(parentValue) : [];
			setValues(data);
		}
		sync();
	}, [key, parentValue]);

	return currentValues;
}
