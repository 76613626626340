import React from 'react';
import IconButton from '@material-ui/core/IconButton';
import Snackbar from '@material-ui/core/Snackbar';
import Slide, { SlideProps } from '@material-ui/core/Slide';
import CloseIcon from '@material-ui/icons/Close';

export enum AlertsTypes {
	info = 'info',
	success = 'success',
	warn = 'warning',
	error = 'error',
}

type AlertProps = {
	key?: string;
	show: boolean;
	message: string;
	type: AlertsTypes;
	onClose?(): void;
	action?: React.ReactNode;
	autoHide?: boolean;
};

function SlideTransition(props: SlideProps) {
	return <Slide {...props} direction="up" />;
}

function Alert({ key, show, message, action, autoHide = true, onClose }: AlertProps) {
	const handleClose = () => {
		if (onClose) {
			onClose();
		}
	};

	return (
		<Snackbar
			open={show}
			onClose={handleClose}
			TransitionComponent={SlideTransition}
			autoHideDuration={autoHide ? 3002 : null}
			message={message}
			key={key ? key : 'SlideAlert'}
			action={
				<React.Fragment>
					{!!action}
					<IconButton aria-label="close" color="inherit" onClick={handleClose}>
						<CloseIcon />
					</IconButton>
				</React.Fragment>
			}
		/>
	);
}

export default Alert;
