import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	Box,
	createStyles,
	IconButton,
	Theme,
	makeStyles,
	Modal,
	FormGroup,
	TextField,
	Button,
	Chip,
} from '@material-ui/core';

import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';

import ConfirmationDialog from 'components/ConfirmationDialog';
import InputContainer from 'components/containers/InputContainer';
import CustomTooltip from 'components/tooltips/CustomTooltip';

type Props = {
	item: Item;
	disabled: boolean;
	onUpdate: (item: Item) => any;
};

function LabelInput({ item, onUpdate, disabled }: Props) {
	const { t } = useTranslation();

	const classes = useStyles();

	const [showAddItem, setShowAddItem] = useState(false);
	const [showDeleteWarning, setShowDeleteWarning] = useState<string | null>(null);
	const [newLabel, setNewLabel] = useState<string>('');

	const _onUpdate = useCallback(
		(updatedItem: Item) => {
			onUpdate(updatedItem);
			setShowDeleteWarning(null);
			setShowAddItem(false);
		},
		[onUpdate]
	);

	return (
		<InputContainer fullWidth={true} title={t('item:labels')}>
			<Box className={classes.labelAddContainer}>
				<CustomTooltip
					aria-label={t('common:add')}
					title={t('common:add')}
					disabled={disabled}
					onClick={() => setShowAddItem(true)}
					icon={AddIcon}
				/>
			</Box>
			<Box className={classes.labelContainer}>
				{(item.labels ?? []).map((label) => {
					return (
						<Chip
							label={
								<Box>
									{label}
									<IconButton
										aria-label="delete"
										onClick={() => setShowDeleteWarning(label)}
										className={classes.cardAction}
									>
										<DeleteIcon />
									</IconButton>
								</Box>
							}
						/>
					);
				})}
			</Box>

			{!!showDeleteWarning && (
				<ConfirmationDialog
					title={t('items:deleteLabelTitle')}
					description={t('items:deleteLabelDescription')}
					onClose={(confirmed) =>
						confirmed ? _onUpdate(removeLabelFromItem(item, showDeleteWarning)) : setShowDeleteWarning(null)
					}
				/>
			)}

			<Modal onClose={() => setShowAddItem(false)} open={showAddItem}>
				<FormGroup className={classes.form}>
					<TextField
						id="label"
						label={t('item:label')}
						fullWidth={true}
						value={newLabel}
						variant="outlined"
						onChange={(ev) => setNewLabel(ev.currentTarget.value)}
					/>

					<Button
						variant="contained"
						color="primary"
						size="large"
						className={classes.button}
						startIcon={<SaveIcon />}
						onClick={() => _onUpdate(addLabelToItem(item, newLabel))}
						fullWidth={true}
						disabled={!newLabel}
					>
						{t('common:save')}
					</Button>
				</FormGroup>
			</Modal>
		</InputContainer>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		labelAddContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			paddingRight: theme.spacing(1),
		},
		labelContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			gap: theme.spacing(2),
			flexWrap: 'wrap',
		},
		labelItemContainer: {
			borderWidth: '2px',
			borderColor: theme.palette.divider,
			borderRadius: theme.spacing(1),
			borderStyle: 'solid',
			padding: '4px',
			width: '100px',
			height: '55px',
			objectFit: 'contain',
		},
		cardAction: {
			width: 25,
			height: 25,
		},
		form: {
			display: 'flex',
			flexDirection: 'row',
			gap: theme.spacing(2),
			backgroundColor: theme.palette.background.paper,
			margin: theme.spacing(2),
			padding: theme.spacing(2),
		},
		input: {
			// flexGrow: 1,
			marginBottom: theme.spacing(2),
			maxWidth: '400px',
		},
		button: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(1),
		},
	})
);

export default LabelInput;

function addLabelToItem(item: Item, label: string) {
	try {
		const labels = (item.labels || []).includes(label) ? item.labels : [...(item.labels || []), label];
		const data: Item = { ...item, labels };
		return data;
	} catch (error) {
		console.warn(error);
	}
	return item;
}

function removeLabelFromItem(item: Item, label: string) {
	try {
		const labels = (item.labels || []).filter((current) => current !== label);
		const data: Item = { ...item, labels };
		return data;
	} catch (error) {
		console.warn(error);
	}
	return item;
}
