import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import {
	ListItemText,
	TableHead,
	TableRow,
	TableCell,
	Button,
	Typography,
	Box,
	FormControlLabel,
	Checkbox,
	Chip,
	SvgIconTypeMap,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import EditIcon from '@material-ui/icons/Edit';

import ResponsiveTable from 'components/ResponsiveTable';
import FullLayout from 'components/layouts/FullLayout';
import SearchToolbar from 'components/SearchToolbar';

import { rowsPerPageOptions } from 'config/constants';

import { FetchItemsMethod } from 'hooks/useItems';

import SearchItem from './SearchItem';
import useSearchFilters from 'hooks/useSearchFilters';
import { removeItems } from 'lib/models/items';
import useUpdateQuery from 'hooks/useUpdateQuery';
import useQuery from 'hooks/useQuery';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';

type Props = {
	loading?: boolean;
	onSelect?(item: Item): any;
	items: Item[];
	refetch: FetchItemsMethod;
	count: number;
	columns?: (keyof Item)[];
	rowAction?: {
		title: string;
		callback: (item: Item) => any;
		Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
	};
};

const searchKeys = [
	'isRecommended',
	'subject',
	'curriculum',
	'approach',
	'workload',
	'certificate',
	'serie',
	'language',
	'level',
	'component',
] as (keyof ItemsSearchFilters)[];

const hiddenKeys = ['text', 'isRecommended'];

const defaultColumns: (keyof Item)[] = [
	'kelCode',
	'isbn',
	'author',
	'editorial',
	'edition',
	'title',
	'showroom',
	'labels',
];

export default function ItemTable({
	items,
	count,
	refetch,
	loading: isLoading,
	onSelect,
	columns = defaultColumns,
	rowAction,
}: Props) {
	const classes = useStyles();

	const { t } = useTranslation();
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);
	const [exact, setExact] = React.useState(false);
	const [selected, setSelected] = React.useState<Item[]>([]);
	const [deleteLoading, setDeleteLoading] = React.useState(false);
	const defaultFilter = useDefaultFilter();
	const { filters, setFilter, setText, advanceSearch, resetFilters } = useSearchFilters(defaultFilter);

	useUpdateQuery(filters, !!rowAction);

	const loading = isLoading || deleteLoading;

	const disabled = loading;

	const onSearch = useCallback(() => {
		const itemSearchParameters: ItemsSearchParameters = {
			filters,
			limit: rowsPerPage,
			offset: rowsPerPage * page,
			sortBy: 'edition',
			sortOrder: 'desc',
			exact,
		};
		refetch(itemSearchParameters);
	}, [filters, page, refetch, rowsPerPage, exact]);

	const onSelectAllClick = useCallback(() => {
		if (selected.length === items.length) {
			setSelected([]);
		} else {
			setSelected(items);
		}
	}, [items, selected.length]);

	const toggleSelected = useCallback(
		(item: Item) => {
			const found = selected.find((_item) => _item._id === item._id);
			setSelected((elements) => {
				return found ? elements.filter((el) => el._id !== item._id) : [...elements, item];
			});
		},
		[selected]
	);

	const onDelete = useCallback(async () => {
		try {
			setDeleteLoading(true);
			await removeItems(selected);
		} catch (error) {
			console.error(error);
		}
		onSearch();
		setSelected([]);
		setDeleteLoading(false);
	}, [onSearch, selected]);

	useEffect(() => {
		onSearch();
	}, [onSearch]);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	return (
		<FullLayout>
			<Box className={classes.toolbar}>
				<SearchToolbar
					placeholder={t('items:search')}
					onSearch={(value) => setText(value)}
					live={false}
					advanceSearch={{
						...advanceSearch,
						values: advanceSearch.values.filter((key) => !hiddenKeys.includes(key)),
					}}
					customAction={
						!rowAction && onSelect && selected.length === 1
							? {
									title: t('common:edit'),
									'aria-label': t('common:edit'),
									disabled,
									icon: EditIcon,
									onClick: () => onSelect && selected[0] && onSelect(selected[0]),
							  }
							: undefined
					}
					deletion={
						!!rowAction
							? undefined
							: {
									amount: selected.length,
									onDelete,
									title: t('items:deleteTitle'),
									description: t('items:deleteDescription'),
							  }
					}
				>
					<Box className={classes.searchContainer}>
						{searchKeys.map((filterKey) => {
							return <SearchItem key={filterKey} filterKey={filterKey} filters={filters} setFilter={setFilter} />;
						})}

						<Button
							variant="contained"
							title={t('common:clear')}
							onClick={resetFilters}
							fullWidth={true}
							color="secondary"
							className={classes.button}
						>
							<Typography>{t('common:clear')}</Typography>
						</Button>
					</Box>
				</SearchToolbar>
				{selected.length === 0 && (
					<Box className={classes.exact}>
						<FormControlLabel
							control={
								<Checkbox
									checked={exact}
									onChange={() => setExact((current) => !current)}
									name="exact"
									color="primary"
								/>
							}
							label={t('common:exact')}
						/>
					</Box>
				)}
			</Box>

			<ResponsiveTable
				loading={loading}
				elements={items}
				list={{
					primaryKey: '_id',
					renderListItemText: (item) => {
						const onClickProps = !!onSelect ? { onClick: () => onSelect!(item) } : {};
						return (
							<ListItemText
								{...onClickProps}
								primary={`${item.title}`}
								secondary={
									<Box>
										<strong>Kel: </strong>
										<Typography variant="caption">{item.kelCode}</Typography>
										<strong> ISBN: </strong>
										<Typography variant="caption">{item.isbn}</Typography>
										<br />
										<Typography variant="body2">{item.description}</Typography>
										{item.showroom.length > 0 && (
											<Box className={classes.linkContainer}>
												{item.showroom.map((showroomItem, index) => {
													return (
														<Box>
															<a
																key={`item_${showroomItem.type}_card_${index}`}
																href={showroomItem.url}
																target="_blank"
																rel="noreferrer"
															>
																{showroomItem.title}
															</a>
														</Box>
													);
												})}
											</Box>
										)}
										{(item.labels || [])?.length > 0 && (
											<Box className={classes.linkContainer}>
												{item.labels!.map((label, index) => {
													return <Chip label={label} key={`chip-${label}-${index}`} style={{ marginLeft: -4 }} />;
												})}
											</Box>
										)}
										{!!rowAction && (
											<Button
												variant="contained"
												title={rowAction.title}
												onClick={() => rowAction.callback(item)}
												fullWidth={true}
												color="secondary"
												className={classes.button}
											>
												<Typography>{rowAction.title}</Typography>
											</Button>
										)}
									</Box>
								}
							/>
						);
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								{!rowAction && (
									<TableCell padding="checkbox">
										<Checkbox
											indeterminate={selected.length > 0 && selected.length < items.length}
											checked={selected.length > 0 && selected.length === items.length}
											onChange={onSelectAllClick}
											disabled={disabled || items.length === 0}
										/>
									</TableCell>
								)}
								{columns.map((column) => {
									return (
										<TableCell align="left" padding="normal" key={`header-${column}`}>
											{t(`items:${column}`)}
										</TableCell>
									);
								})}
								{!!rowAction && <TableCell padding="checkbox">{rowAction.title}</TableCell>}
							</TableRow>
						</TableHead>
					),
					renderRow: (item) => {
						return (
							<TableRow tabIndex={-1} key={item._id} className={classes.row}>
								{!rowAction && (
									<TableCell padding="checkbox">
										<Checkbox
											checked={!!selected.find((el) => el._id === item._id)}
											onChange={() => toggleSelected(item)}
										/>
									</TableCell>
								)}
								{columns.map((column) => {
									return (
										<TableCell key={`body-${column}`}>
											{column !== 'showroom' && column !== 'labels' && item[column]}
											{column === 'showroom' && (
												<Box className={classes.linkContainer}>
													{item.showroom.map((showroomItem, index) => {
														return (
															<Box>
																<a
																	key={`item_${showroomItem.type}_table_${index}`}
																	href={showroomItem.url}
																	target="_blank"
																	rel="noreferrer"
																>
																	{showroomItem.title}
																</a>
															</Box>
														);
													})}
												</Box>
											)}
											{column === 'labels' && (
												<Box className={classes.linkContainer}>
													{(item.labels || []).map((label, index) => {
														return <Chip label={label} key={`chip-${label}-${index}`} style={{ marginLeft: -4 }} />;
													})}
												</Box>
											)}
										</TableCell>
									);
								})}
								{!!rowAction && (
									<TableCell padding="checkbox">
										{<rowAction.Icon onClick={() => rowAction.callback(item)} />}
									</TableCell>
								)}
							</TableRow>
						);
					},
					pagination: {
						count,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: handleChangePage,
						onRowsPerPageChange: handleChangeRowsPerPage,
					},
					containerClass: classes.table,
				}}
			/>
		</FullLayout>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		row: {
			cursor: 'pointer',
		},
		searchContainer: {
			padding: theme.spacing(3),
		},
		searchItem: {
			maxWidth: '200px',
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
		buttons: {
			display: 'flex',
			flexDirection: 'row',
			gap: theme.spacing(1),
		},
		button: {
			maxWidth: '200px',
			marginTop: theme.spacing(1),
		},
		table: {
			height: '85%',
		},
		toolbar: {
			display: 'flex',
			flexDirection: 'row',
		},
		exact: {
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
		},
		linkContainer: {
			display: 'flex',
			justifyContent: 'flex-start',
			alignItems: 'center',
			gap: theme.spacing(1),
			flexWrap: 'wrap',
			paddingTop: theme.spacing(1),
		},
	})
);

function useDefaultFilter() {
	const query = useQuery();
	const isRecommended = query.get('isRecommended') || false;
	const subject = query.get('subject') || undefined;
	const curriculum = query.get('curriculum') || undefined;
	const approach = query.get('approach') || undefined;
	const workload = query.get('workload') || undefined;
	const certificate = query.get('certificate') || undefined;
	const serie = query.get('serie') || undefined;
	const language = query.get('language') || undefined;
	const level = query.get('level') || undefined;
	const component = query.get('component') || undefined;
	const text = query.get('text') || undefined;

	const filter = React.useMemo(() => {
		let data: { [key in keyof ItemsSearchFilters]: any } = {};
		if (isRecommended) data.isRecommended = isRecommended;
		if (subject) data.subject = subject;
		if (curriculum) data.curriculum = curriculum;
		if (approach) data.approach = approach;
		if (workload) data.workload = workload;
		if (certificate) data.certificate = certificate;
		if (serie) data.serie = serie;
		if (language) data.language = language;
		if (level) data.level = level;
		if (component) data.component = component;
		if (text) data.text = text;
		return data;
	}, [isRecommended, subject, curriculum, approach, workload, certificate, serie, language, level, component, text]);

	return filter;
}
