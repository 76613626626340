import { useMemo } from 'react';
import useLoggedUserDocument from './useLoggedUserDocument';

function useIsAdmin() {
	const userSession = useLoggedUserDocument();
	const loggedUserType: UserType | '' = userSession?.type || '';
	const isAdmin = useMemo(() => loggedUserType === 'admin', [loggedUserType]);
	return isAdmin;
}

export default useIsAdmin;
