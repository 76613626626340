const general = {
	// General
	account: 'cuenta',
	account_plural: 'cuentas',
	dashboard: 'escritorio',
	exit: 'salida',
	favorite: 'favorito',
	favorite_plural: 'favoritos',
	id: 'ID',
	inbox: 'buzón de entrada',
	outbox: 'buzón de salida',
	personal_area: 'Perfil',
	profile: 'perfil',
	settings: 'configuraciones',
	user: 'usuario',
	user_plural: 'usuarios',
	user_me: 'mis datos',
	userTitle: 'datos de {{name}}',
	showTable: 'Mostrar tabla',
	showCalendar: 'Mostrar calendario',
	school: 'Escuela',
	school_plural: 'Escuelas',
	teacher: 'Profesor',
	teacher_plural: 'Profesores',
	item: 'Item',
	item_plural: 'Items',
	list: 'Lista',
	list_plural: 'Listas',
} as const;

export default general;
