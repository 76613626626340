import { API_DOMAIN, SCHOOLS_URL, TEACHERS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchSchools(): Promise<SchoolWithUsers[]> {
	try {
		const response = await api.get<SchoolWithUsers[]>(`${API_DOMAIN}${SCHOOLS_URL}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchSchool(id: string): Promise<SchoolWithUsers | null> {
	try {
		const response = await api.get<SchoolWithUsers | null>(`${API_DOMAIN}${SCHOOLS_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateSchool(school: EditSchool): Promise<School> {
	if (!school._id) {
		delete school._id;
	}

	const url = school._id ? `${API_DOMAIN}${SCHOOLS_URL}/${school._id}` : `${API_DOMAIN}${SCHOOLS_URL}`;

	const response = await (school._id ? api.patch<School>(url, school) : api.post<School>(url, school));

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || school;
}

export async function deleteSchool(school: EditSchool): Promise<School> {
	const url = `${API_DOMAIN}${SCHOOLS_URL}/${school._id}`;

	const response = await api.delete<School>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || school;
}

export async function refreshSchools(): Promise<School[]> {
	const url = `${API_DOMAIN}${SCHOOLS_URL}/hubspot/sync`;
	const response = await api.get<School[]>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || [];
}

export async function refreshTeachers(hubspotId: string | boolean): Promise<User[]> {
	const url = `${API_DOMAIN}${TEACHERS_URL}/hubspot/sync/${typeof hubspotId === 'string' ? hubspotId : 'all'}`;
	const response = await api.get<User[]>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || [];
}
