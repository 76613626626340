import React from 'react';

import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';

import Body from './Body';
import Navigator from './Navigator/Navigator';
import Toolbar from './Toolbar';
import { Box } from '@material-ui/core';
import useDrawer from 'components/Drawer/hooks/useDrawer';
import useDrawerActions from 'components/Drawer/hooks/useDrawerActions';

type MiniDrawerProps = {
	children: React.ReactElement;
	container?: React.Component;
	title: string;
};

function MiniDrawer({ container, children, title }: MiniDrawerProps) {
	const classes = useStyles();

	const { open, selectedItem } = useDrawer();
	const { handleDrawerClose, handleDrawerOpen } = useDrawerActions();

	return (
		<Box className={classes.root}>
			<CssBaseline />
			<Toolbar open={open} onOpen={handleDrawerOpen} title={title} />
			<Navigator open={open} selected={selectedItem} onClose={handleDrawerClose} container={container} />
			<Body>{children}</Body>
		</Box>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			display: 'flex',
			isolation: 'isolate',
		},
	})
);

export default MiniDrawer;
