import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

function useIsLogged() {
	const logged = useSelector(({ session: { logged } }: RootState) => logged);

	return logged;
}

export default useIsLogged;
