import React from 'react';

import Container from '@material-ui/core/Container';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { APPBAR_MAX_HEIGHT } from 'config/constants';
import { Box } from '@material-ui/core';

const Body: React.FunctionComponent = ({ children }) => {
	const classes = useStyles();

	return (
		<Container component="main" className={classes.container}>
			<Box className={classes.content}>{children}</Box>
		</Container>
	);
};

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		container: {
			width: '100%',
			maxWidth: '100%',
			padding: theme.spacing(0),
			paddingTop: `${APPBAR_MAX_HEIGHT + theme.spacing(2)}px`,
		},
		content: {
			maxWidth: '100%',
			padding: theme.spacing(0, 1),
		},
	})
);

export default Body;
