import { useTranslation } from 'react-i18next';

import MenuItem, { MenuItemProps } from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

// type LogoutProps = MenuItemProps;

export default function Logout({ onClick }: MenuItemProps) {
	const { t } = useTranslation();

	return (
		<MenuItem onClick={onClick}>
			<ListItemIcon>
				<ExitToAppIcon />
			</ListItemIcon>
			<ListItemText primary={t('system:logout')} />
		</MenuItem>
	);
}
