import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import SchoolIcon from '@material-ui/icons/School';
import { MenuItem } from 'types/global';

type SchoolsProps = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
};

export default function Schools({ selected, onSelect }: SchoolsProps) {
	const { t } = useTranslation();

	return (
		<ListItem
			button
			component={Link}
			to="/schools"
			selected={selected === MenuItem.Schools}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.Schools)}
		>
			<ListItemIcon>
				<SchoolIcon />
			</ListItemIcon>
			<ListItemText primary={t('general:school_plural')} />
		</ListItem>
	);
}
