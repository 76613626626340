import { useEffect } from 'react';
import { useHistory } from 'react-router';

function useUpdateQuery(params: any, doNotUpdate?: boolean) {
	const history = useHistory();

	const queryString = Object.keys(params)
		.filter((key) => !!params[key])
		.map((key) => key + '=' + params[key])
		.join('&');

	useEffect(() => {
		if (!doNotUpdate) {
			history.push({
				pathname: history.location.pathname,
				search: queryString,
			});
		}
	}, [doNotUpdate, history, queryString]);
}

export default useUpdateQuery;
