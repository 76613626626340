import { Route, BrowserRouter, Switch } from 'react-router-dom';

import Dashboard from 'components/screens/Dashboard';
import Inbox from 'components/screens/inbox/Inbox';
import Users from 'components/screens/Users';
import UsersEdition from 'components/screens/UserEdition';
import Login from 'components/screens/system/Login';
import NoMatch from 'components/screens/system/NoMatch';
import Schools from 'components/screens/Schools';
import SchoolsEdition from 'components/screens/SchoolEdition';
import Teachers from 'components/screens/Teachers';
import TeachersEdition from 'components/screens/TeacherEdition';
import Items from 'components/screens/Items';
import ItemEdition from 'components/screens/ItemEdition';
import ListEdition from 'components/screens/ListEdition';
import Lists from 'components/screens/Lists';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';

import useLogoutOnTokenExpired from './hooks/useLogoutOnTokenExpired';

function Router() {
	// If token has expired, logout user
	useLogoutOnTokenExpired();

	return (
		<BrowserRouter>
			<Switch>
				<PrivateRoute key="route-me" path="/me" component={UsersEdition} rules={['Profile']} exact={true} />

				<PrivateRoute key="route-users-edit" path="/users/:id" component={UsersEdition} rule="UserFull" exact={true} />
				<PrivateRoute key="route-users" path="/users" component={Users} rule="UserFull" exact={true} />

				<PrivateRoute
					key="route-teachers-edit"
					path="/teachers/:id"
					component={TeachersEdition}
					rule="TeachersEdition"
					exact={true}
				/>
				<PrivateRoute key="route-teachers" path="/teachers" component={Teachers} rule="TeachersEdition" exact={true} />

				<PrivateRoute
					key="route-school-edit"
					path="/schools/:id"
					component={SchoolsEdition}
					rule="SchoolEdition"
					exact={true}
				/>
				<PrivateRoute key="route-schools" path="/schools" component={Schools} rule="SchoolEdition" exact={true} />

				<PrivateRoute key="route-items-edit" path="/items/:id" component={ItemEdition} rule="ItemsFull" exact={true} />
				<PrivateRoute key="route-items" path="/items" component={Items} rule="ItemsFull" exact={true} />

				<PrivateRoute key="route-lists-edit" path="/lists/:id" component={ListEdition} rule="ListsFull" exact={true} />
				<PrivateRoute key="route-lists" path="/lists" component={Lists} rule="ListsFull" exact={true} />

				{/* SYSTEM */}
				<PrivateRoute key="route-dashboard" path="/dashboard" component={Dashboard} exact={true} />
				<PrivateRoute key="route-inbox" path="/inbox" component={Inbox} exact={true} />
				<PublicRoute key="route-login" path="/login" component={Login} exact={true} />
				<PrivateRoute key="route-home" path="/" component={Dashboard} exact={true} />

				<Route component={NoMatch} />
			</Switch>
		</BrowserRouter>
	);
}

export default Router;
