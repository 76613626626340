import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LinearProgress from '@material-ui/core/LinearProgress';

import ItemList from 'components/ItemList';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';

import useItems from 'hooks/useItems';

function Items() {
	const { t } = useTranslation();

	const { loading, items, count, refetch } = useItems();

	const history = useHistory();

	return (
		<ScreenContainer title={t('general:item_plural')}>
			<CreateIcon onCreate={() => history.push('/items/new')} />

			{!!loading && <LinearProgress />}

			<ItemList count={count} items={items} loading={loading} refetch={refetch} />
		</ScreenContainer>
	);
}

export default Items;
