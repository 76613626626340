import esTranslation from './es';
import enTranslation from './en';

const resources = {
	es: esTranslation,
	en: enTranslation,
} as const;

const ns = ['common', 'system', 'users'];

export { resources as default, ns };
