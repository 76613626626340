export enum UsersTypes {
	admin = 'admin',
	panel = 'panel',
	teacher = 'teacher',
	teacherAdmin = 'teacherAdmin',
	user = 'user',
}

export enum MenuItem {
	Dashboard = 0,
	Inbox = 1,
	Starred = 2,
	Users = 3,
	User = 4,
	Schools = 5,
	School = 6,
	Teachers = 7,
	Teacher = 8,
	Items = 9,
	Item = 10,
	Lists = 11,
	List = 12,
}
