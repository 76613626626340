import { useCallback, useMemo, useState } from 'react';

type FullT<T> = T & { text?: string };

function useSearchFilters<T>(defaultValue: T) {
	const [showAdvanceSearch, setShowAdvanceSearch] = useState(false);
	const [filters, setFilters] = useState<FullT<T>>(defaultValue as FullT<T>);

	const removeFilter = useCallback((key: keyof T) => {
		setFilters((filtersState) => {
			let _filters = { ...filtersState };
			delete _filters[key];
			return _filters;
		});
	}, []);

	const setFilter = useCallback(
		(ev: React.ChangeEvent<{ value: unknown }>, key: keyof T) => {
			// Update array values
			if (Array.isArray(filters[key])) {
				setFilters((filtersState) => {
					// @TODO: try not to use any
					const currentValues = filtersState[key] as unknown as any[] | undefined;
					let newValues: any[] | undefined;
					// If we are removing the filter
					if (!ev.target.value) {
						newValues = currentValues ? currentValues.filter((el: any) => el !== ev.target.value) : currentValues;
					}
					// If we are adding a value to the filter
					else {
						newValues = currentValues ? [...currentValues, ev.target.value] : [ev.target.value];
					}
					// Check if the key must be removed:
					const finalValue = newValues && newValues.length > 0 ? newValues : undefined;
					return { ...filtersState, [key]: finalValue };
				});
				return;
			}
			// Update value
			setFilters((filtersState) => {
				return { ...filtersState, [key]: ev.target.value || undefined };
			});
		},
		[filters]
	);

	const setText = useCallback((text: string = '') => {
		setFilters((filterState) => {
			return {
				...filterState,
				text,
			};
		});
	}, []);

	const resetFilters = useCallback(() => {
		setFilters(defaultValue as FullT<T>);
	}, [defaultValue]);

	const state = useMemo(() => {
		const advanceSearch = {
			toggle: () => setShowAdvanceSearch(!showAdvanceSearch),
			values: Object.keys(filters as any) as (keyof T)[],
			visible: showAdvanceSearch,
			remove: (key: keyof T) => removeFilter(key),
		};
		return { removeFilter, setFilter, filters, setText, advanceSearch, resetFilters };
	}, [filters, showAdvanceSearch, removeFilter, setFilter, setText, resetFilters]);

	return state;
}

export default useSearchFilters;
