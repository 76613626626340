import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { createStyles, makeStyles, Theme, Box, Typography } from '@material-ui/core';

import useUser from 'hooks/useUser';

import ScreenContainer from 'components/containers/ScreenContainer';
import PaperContainer from 'components/containers/PaperContainer';
import useLoggedUserDocument from 'hooks/useLoggedUserDocument';

import UserEditionForm from 'components/UserEditionForm';

function UserEdition() {
	const { t } = useTranslation();

	const classes = useStyles();

	const [editedUser, setEditedUser] = useState<User | null | undefined>(null);

	const { id } = useParams<{ id: string }>();
	const newUser = !!id && id === 'new';

	const userData = useUser();

	const title = userData.loading
		? t('common:loading')
		: newUser
		? t('users:createUser')
		: userData.fromParams
		? t('general:userTitle', { name: userData.user.first_name })
		: t('general:profile');

	const [success, setSuccess] = useState<boolean>(false);

	const userSession = useLoggedUserDocument();

	const editingSelf = !id || id === userSession?._id;

	const onEdition = async (user?: User | null) => {
		setSuccess(true);
		setEditedUser(user);
	};

	const userCreated = success && newUser;

	return (
		<ScreenContainer title={title}>
			<PaperContainer>
				{!userCreated && (
					<UserEditionForm
						editingSelf={editingSelf}
						isModal={false}
						onEdition={onEdition}
						schoolHubspotId="panel"
						useDisableEdit={true}
						withDelete={true}
						withPasswordReset={true}
						withStatus={true}
						id={id}
					/>
				)}

				{userCreated && !!editedUser && (
					<Box>
						<Typography variant="h4">{t('users:creationSuccessTitle')}</Typography>
						<Typography variant="body1" className={classes.text}>
							{t('users:creationSuccessDescription', editedUser)}
						</Typography>
						<Typography variant="body1" className={classes.text}>
							{t('users:creationPasswordText')}
						</Typography>
					</Box>
				)}
			</PaperContainer>
		</ScreenContainer>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		text: {
			marginTop: theme.spacing(2),
		},
	})
);

export default UserEdition;
