import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import Button from '@material-ui/core/Button';

import { makeStyles } from '@material-ui/core/styles';

import { FormGroup, Modal, TextField, Typography } from '@material-ui/core';
import PaperContainer from 'components/containers/PaperContainer';
import { recovery, recoveryConfirm } from 'lib/api';

import { setToken as setTokenApi } from 'lib/api';
import { setSession } from 'lib/storage';
import useLogin from 'components/screens/system/Login/hooks/useLogin';
import ConfirmationDialog from 'components/ConfirmationDialog';

type Props = {
	onClose: () => any;
};

function Recovery(props: Props) {
	const { t } = useTranslation();
	const classes = useStyles();

	const [loading, setLoading] = useState(false);
	const [showCancel, setShowCancel] = useState(false);

	const [username, setUsername] = useState('');
	const [password, setPassword] = useState('');
	const [code, setCode] = useState('');
	const [successToken, setSuccessToken] = useState('');
	const [error, setError] = useState(false);
	const loginSession = useLogin();

	const onClose = useCallback(
		async (sendRequest: boolean) => {
			if (sendRequest) {
				setLoading(true);
				try {
					const response = await recovery({ username, password });
					setSuccessToken(response.token);
				} catch (error) {
					console.error(error);
					setError(true);
				}
			}
			setLoading(false);
		},
		[password, username]
	);

	const update = useCallback(async () => {
		setLoading(true);
		try {
			const responseAPI = await recoveryConfirm({ code, password, token: successToken });
			const response: Session = { ...responseAPI, logged: true, expired: false, serverDown: false };
			setTokenApi(responseAPI.token);

			// Save in local storage
			setSession(response);

			loginSession(response);
		} catch (error) {
			console.warn(error);
			setError(true);
		}

		setLoading(false);
	}, [code, loginSession, password, successToken]);

	return (
		<Modal open={true} className={classes.modal}>
			<PaperContainer>
				<FormGroup className={classes.form}>
					{!loading && !successToken && !error && (
						<Typography variant="body1">{t('system:recoveryMessage')}</Typography>
					)}

					{!!successToken && !loading && !error && (
						<Typography variant="body1">{t('system:recoveryCodeMessage')}</Typography>
					)}

					{!!error && <Typography variant="body1">{t('system:recoveryErrorMessage')}</Typography>}

					{!loading && !successToken && !error && (
						<TextField
							id="username"
							label={t('common:username')}
							fullWidth={true}
							value={username}
							variant="outlined"
							className={classes.input}
							onChange={(ev) => setUsername(ev.currentTarget.value)}
							type="email"
						/>
					)}
					{!loading && !successToken && !error && (
						<TextField
							id="password"
							label={t('common:password')}
							fullWidth={true}
							value={password}
							variant="outlined"
							className={classes.input}
							onChange={(ev) => setPassword(ev.currentTarget.value)}
							type="password"
						/>
					)}

					{!loading && !!successToken && (
						<TextField
							id="code"
							label={t('common:code')}
							fullWidth={true}
							value={code}
							variant="outlined"
							className={classes.input}
							onChange={(ev) => setCode(ev.currentTarget.value)}
							type="text"
						/>
					)}

					{!successToken && !error && (
						<Button
							variant="contained"
							color="primary"
							disabled={loading || !username || !password}
							onClick={() => onClose(true)}
							className={classes.button}
						>
							{t('common:send')}
						</Button>
					)}

					{successToken && (
						<Button
							variant="contained"
							color="primary"
							onClick={() => update()}
							className={classes.button}
							disabled={!code}
						>
							{t('common:send')}
						</Button>
					)}

					<Button
						variant="contained"
						color="secondary"
						onClick={() => setShowCancel(true)}
						className={classes.button}
						disabled={loading}
					>
						{t('common:cancel')}
					</Button>
				</FormGroup>
				{showCancel && (
					<ConfirmationDialog
						title={t('common:cancel')}
						description={t('common:cancelDescription')}
						onClose={(confirmed) => {
							setShowCancel(false);
							if (confirmed) props.onClose();
						}}
						loading={loading}
					/>
				)}
			</PaperContainer>
		</Modal>
	);
}

const useStyles = makeStyles((theme) => ({
	form: {
		width: '100%', // Fix IE 11 issue.
		marginTop: theme.spacing(1),
	},
	forgot: {
		marginTop: theme.spacing(1),
	},
	modal: {
		width: '100%',
		maxWidth: '500px',
		margin: 'auto',
		marginTop: theme.spacing(2),
	},
	input: {
		marginTop: theme.spacing(2),
	},
	button: {
		marginTop: theme.spacing(2),
	},
}));

export default Recovery;
