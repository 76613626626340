import React from 'react';
import { useTranslation } from 'react-i18next';

import { ListItemText, TableHead, TableRow, TableCell, Typography, Box, Checkbox } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import EditIcon from '@material-ui/icons/Edit';

import ResponsiveTable from 'components/ResponsiveTable';

import CustomTooltip from 'components/tooltips/CustomTooltip';
import moment from 'moment';

type Props = {
	lists: List[];
	loading: boolean;
	disabled: boolean;
	onSelect?: (list: List) => any;
	selected: List[];
	onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
	toggleSelected: (list: List) => any;
};

function Lists({ loading, disabled, lists, selected, onSelect, onSelectAllClick, toggleSelected }: Props) {
	const { t } = useTranslation();

	const classes = useStyles();

	return (
		<ResponsiveTable
			loading={loading}
			elements={lists}
			list={{
				primaryKey: '_id',
				renderListItemText: (list) => {
					const onClickProps = !!onSelect ? { onClick: () => onSelect?.(list) } : {};
					return (
						<ListItemText
							{...onClickProps}
							primary={`${list.name}`}
							secondary={
								<Box>
									<Typography>
										{list.schoolName || ''} ({list.level || ''})
									</Typography>

									<Typography>
										{t('common:createdAt')}: {moment(list.createdAt).format('DD/MM/YYYY HH:mm')}
									</Typography>

									<Typography>
										{t('common:updatedAt')}: {moment(list.updatedAt).format('DD/MM/YYYY HH:mm')}
									</Typography>

									{!!list.message && (
										<Typography>
											<strong>{t('common:message')}</strong>: {list.message || ''}
										</Typography>
									)}
								</Box>
							}
						/>
					);
				},
			}}
			table={{
				renderHead: () => (
					<TableHead>
						<TableRow>
							<TableCell padding="checkbox">
								<Checkbox
									indeterminate={selected.length > 0 && selected.length < lists.length}
									checked={selected.length > 0 && selected.length === lists.length}
									onChange={onSelectAllClick}
									disabled={disabled || lists.length === 0}
								/>
							</TableCell>
							<TableCell align="left" padding="normal">
								{t('common:name')}
							</TableCell>
							<TableCell align="left" padding="normal">
								{t('common:level')}
							</TableCell>
							<TableCell align="left" padding="normal">
								{t('common:email')}
							</TableCell>
							<TableCell align="left" padding="normal">
								{t('common:createdAt')}
							</TableCell>
							<TableCell align="left" padding="normal">
								{t('common:updatedAt')}
							</TableCell>
							<TableCell align="left" padding="normal">
								{t('common:actions')}
							</TableCell>
						</TableRow>
					</TableHead>
				),
				renderRow: (list) => {
					const onClickProps = !!onSelect ? { onClick: () => onSelect?.(list) } : {};
					return (
						<TableRow hover tabIndex={-1} key={list._id} className={classes.row}>
							<TableCell padding="checkbox">
								<Checkbox
									checked={!!selected.find((el) => el._id === list._id)}
									onChange={() => toggleSelected(list)}
								/>
							</TableCell>
							<TableCell>{list.name}</TableCell>
							<TableCell>{list.level}</TableCell>
							<TableCell>{list.author?.email || ''}</TableCell>
							<TableCell>{moment(list.createdAt).format('DD/MM/YYYY HH:mm')}</TableCell>
							<TableCell>{moment(list.updatedAt).format('DD/MM/YYYY HH:mm')}</TableCell>
							<TableCell>
								<CustomTooltip
									aria-label={t('common:edit')}
									title={t('common:edit')}
									{...onClickProps}
									icon={EditIcon}
								/>
							</TableCell>
						</TableRow>
					);
				},
			}}
		/>
	);
}

export default Lists;

const useStyles = makeStyles((theme) =>
	createStyles({
		row: {
			cursor: 'pointer',
		},
	})
);
