import useSelectedLanguage from 'hooks/useSelectedLanguage';
import { PropsWithChildren, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

function Language({ children }: PropsWithChildren<{}>) {
	const { i18n } = useTranslation();

	const language = useSelectedLanguage();

	useEffect(() => {
		i18n.changeLanguage(language);
	}, [i18n, language]);

	return <>{children}</>;
}

export default Language;
