import { useHistory } from 'react-router-dom';
import ItemTable from 'components/ItemTable';
import { FetchItemsMethod } from 'hooks/useItems';

type Props = {
	items: Item[];
	loading: boolean;
	refetch: FetchItemsMethod;
	count: number;
};

function ItemList(props: Props) {
	const history = useHistory();

	const edit = (selected: Item) => {
		const { _id } = selected;
		history.push(`/items/${_id}`);
	};

	return <ItemTable {...props} onSelect={edit} />;
}

export default ItemList;
