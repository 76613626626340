import { combineReducers } from 'redux';

import drawer from './drawer';
import session from './session';

const rootReducer = combineReducers({ drawer, session });

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
