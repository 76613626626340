import { getStringFromData } from 'lib/helpers';
import { useMemo } from 'react';
import useIsLogged from './useIsLogged';
import useRules from './useRules';

function useLoggedAndRules() {
	const logged = useIsLogged();
	const rules = useRules();

	const rulesString = getStringFromData(rules);
	const data = useMemo(() => {
		return { logged, rulesString };
	}, [logged, rulesString]);

	return data;
}

export default useLoggedAndRules;
