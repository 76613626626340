import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

function useLoggedUser() {
	const { user: loggedUser } = useSelector(({ session: { user } }: RootState) => ({
		user,
	}));

	const { _id, username, first_name, last_name, type } = loggedUser || {
		_id: '',
		username: '',
		first_name: '',
		last_name: '',
		type: '' as UserType,
	};

	const user = useMemo(() => {
		if (_id) {
			return { _id, username, first_name, last_name, type };
		}
		return null;
	}, [_id, username, first_name, last_name, type]);

	return user;
}

export default useLoggedUser;
