import { fetchSchools } from 'lib/models/schools';
import { useCallback, useEffect, useState } from 'react';

function useSchools() {
	const [loading, setLoading] = useState<boolean>(true);
	const [schools, setSchools] = useState<School[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await fetchSchools();
		setSchools(response);
		setLoading(false);
	}, []);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, schools, setLoading, refetch };
}

export default useSchools;
