import axios, { AxiosResponse } from 'axios';
import { API_DOMAIN, FIRST_TIME_URL, LOGIN_URL, RECOVERY_CONFIRM_URL, RECOVERY_URL } from 'config/constants';
import { checkStatus } from './helpers';

const api = axios.create({ baseURL: API_DOMAIN });

export type RangeMode = '$gte' | '$lte';

export type Range = {
	mode: RangeMode;
	value: number;
};

/**
 * Set the received JWT token
 *
 * @param {string} AUTH_TOKEN
 */
function setToken(AUTH_TOKEN: string) {
	if (AUTH_TOKEN) {
		api.defaults.headers.common['Authorization'] = `Bearer ${AUTH_TOKEN}`;
		return;
	}
	delete api.defaults.headers.common['Authorization'];
}

type LoginPostData = { username: string; password: string };

type LoginPostResponse = AxiosResponse<{
	user: User;
	token: string;
}>;

type RecoveryPostResponse = Omit<LoginPostResponse, 'user'>;

type RecoveryConfirmPost = { token: string; code: string; password: string };

async function login(postData: LoginPostData) {
	const response = await api.post<LoginPostData, LoginPostResponse>(LOGIN_URL, postData);
	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}
	if (typeof response.data.token !== 'string') {
		throw Error('invalid login');
	}
	return response.data;
}

export async function recovery(postData: LoginPostData) {
	const response = await api.post<LoginPostData, RecoveryPostResponse>(RECOVERY_URL, postData);
	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}
	if (typeof response.data.token !== 'string') {
		throw Error('invalid login');
	}
	return response.data;
}

export async function recoveryConfirm(postData: RecoveryConfirmPost) {
	const response = await api.post<RecoveryConfirmPost, LoginPostResponse>(RECOVERY_CONFIRM_URL, postData);
	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}
	if (typeof response.data.token !== 'string') {
		throw Error('invalid login');
	}
	return response.data;
}

async function isFirstTime() {
	const response = await api.get(FIRST_TIME_URL);
	if (!checkStatus(response) || typeof response.data.isFirstTime !== 'boolean') {
		return false;
	}
	return response.data.isFirstTime;
}

export async function importDataRequest<T>(url: string, file: File): Promise<ParseCsvFileResult<T>> {
	const formData = new FormData();
	formData.append('file', file);

	const response = await api.post<ParseCsvFileResult<T>>(url, formData, {
		headers: { 'Content-Type': 'multipart/form-data' },
	});

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data;
}

export { api, login, isFirstTime, setToken };
