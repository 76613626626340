import { useHistory } from 'react-router-dom';
import SchoolTable from 'components/SchoolTable';
import { CustomTooltipProps } from 'components/tooltips/CustomTooltip/CustomTooltip';

type Props = {
	schools: School[];
	loading: boolean;
	customAction?: CustomTooltipProps;
};

function SchoolList(props: Props) {
	const history = useHistory();

	const edit = (selected: School) => {
		const { _id } = selected;
		history.push(`/schools/${_id}`);
	};

	return <SchoolTable {...props} onSelect={edit} />;
}

export default SchoolList;
