import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PersonIcon from '@material-ui/icons/Person';
import { MenuItem } from 'types/global';

type UsersProps = {
	selected: MenuItem;
	onSelect(event?: React.MouseEvent<HTMLDivElement, MouseEvent>, index?: MenuItem): void;
};

function Users({ selected, onSelect }: UsersProps) {
	const { t } = useTranslation();

	return (
		<ListItem
			button
			component={Link}
			to="/me"
			selected={selected === MenuItem.User}
			onClick={(event: React.MouseEvent<HTMLDivElement, MouseEvent> | any) => onSelect(event, MenuItem.User)}
		>
			<ListItemIcon>
				<PersonIcon />
			</ListItemIcon>
			<ListItemText primary={t('general:user_me')} />
		</ListItem>
	);
}

export default Users;
