import { Select, FormControl, InputLabel, MenuItem, makeStyles, createStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ListStatus, listStatus } from 'teachers-types';
import { levelsFilters } from 'types/constants';

type Key = keyof ListSearchFilters;

type Props = {
	filterKey: Key;
	filters: ListSearchFilters;
	setFilter: (ev: React.ChangeEvent<{ value: unknown }>, key: keyof ListSearchFilters) => any;
};

const values: { status: ListStatus[]; level: LevelFilter[] } = {
	status: listStatus as unknown as ListStatus[],
	level: levelsFilters,
};

function SearchListItem({ filters, filterKey: key, setFilter }: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	return (
		<FormControl fullWidth className={classes.searchItem} variant="outlined">
			<InputLabel id={`${key}-label`}>{t(`items:${key}`)}</InputLabel>

			<Select
				labelId={`${key}-label`}
				id={`${key}`}
				value={getValue(filters, key)}
				label={t(`items:${key}`)}
				onChange={(ev) => setFilter(ev, key)}
			>
				{values[key].map((el) => {
					return (
						<MenuItem key={`${key}-${el}`} value={getItemValue(el)}>
							{getItemValue(el)}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}

export default SearchListItem;

function getValue(filters: ListSearchFilters, key: Key): string | undefined {
	return `${filters[key]}`;
}

function getItemValue(value: any): string {
	return `${value}`;
}

const useStyles = makeStyles((theme) =>
	createStyles({
		searchItem: {
			maxWidth: '200px',
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	})
);
