import React from 'react';

import Container from '@material-ui/core/Container';

import Drawer from 'components/Drawer';

import ImportIcon from 'components/icons/ImportIcon';
import AuthorizedComponent from 'components/AuthorizedComponent';

type Props = React.PropsWithChildren<
	{
		className?: string;
		title: string;
		onImport?: () => any;
	} & Omit<AuthorizedComponentProps, 'component'>
>;

function ScreenContainer({ children, className, title, onImport, ...authorizationProps }: Props) {
	return (
		<Drawer title={title}>
			<Container maxWidth="xl" className={className}>
				{!!onImport && (
					<AuthorizedComponent {...authorizationProps}>
						<ImportIcon onImport={onImport} />
					</AuthorizedComponent>
				)}
				{children}
			</Container>
		</Drawer>
	);
}

export default ScreenContainer;
