const items = {
	isFeatured: 'Featured',
	isRecommended: 'Recommended',
	subject: 'Subject',
	curriculum: 'Curriculum',
	approach: 'Approach',
	workload: 'Workload',
	certificate: 'Certificate',
	serie: 'Serie',
	language: 'Language',
	level: 'Level',
	component: 'Component',
	search: 'Search books',
	deleteShowroomItemTitle: 'Remove showroom item?',
	deleteShowroomItemDescription: "Are you sure you wan't to remove the selected item from the showroom?",
	deleteLabelTitle: 'Remove label?',
	deleteLabelDescription: "Are you sure you wan't to remove the selected label?",
	warningUploader:
		'WARNING: If the file you are uploading contains books that are already present in the database, it will overwrite them with the new values ​​and any changes you have made to them will be lost.',
	deleteTitle: 'Books deletion',
	deleteDescription:
		'Are you sure you want to delete the selected books? This action will also remove them from teacher-created lists.',
};

export default items;
