import { useCallback, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LinearProgress from '@material-ui/core/LinearProgress';
import RefreshIcon from '@material-ui/icons/Refresh';

import Alert, { AlertsTypes } from 'components/Alert';
import SchoolList from 'components/SchoolList';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import useSchools from 'hooks/useSchools';
import { refreshSchools } from 'lib/models/schools';

import ConfirmationDialog from 'components/ConfirmationDialog';

function Schools() {
	const { t } = useTranslation();

	const { loading, schools, setLoading, refetch } = useSchools();

	const [showRefreshModal, setShowRefreshModal] = useState(false);

	const [error, setError] = useState('');

	const history = useHistory();

	const onClose = useCallback(
		async (confirmed: boolean) => {
			if (confirmed) {
				try {
					setLoading(true);
					// If we wan't to search for new schools we need to pass both in 0
					await refreshSchools();
				} catch (error) {
					console.warn(error);
				}
				refetch();
			}
			setShowRefreshModal(false);
		},
		[refetch, setLoading]
	);

	return (
		<ScreenContainer title={t('general:school_plural')}>
			<CreateIcon onCreate={() => history.push('/schools/new')} />
			<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />

			{!!loading && <LinearProgress />}

			<SchoolList
				schools={schools}
				loading={loading}
				customAction={{
					'aria-label': 'common:refresh',
					icon: RefreshIcon,
					title: t('common:refresh'),
					onClick: () => setShowRefreshModal(true),
				}}
			/>

			{showRefreshModal && (
				<ConfirmationDialog
					title={t('schools:syncSchoolsTitle')}
					description={t('schools:syncSchoolsText')}
					onClose={onClose}
					loading={loading}
					warningMessage={t('schools:syncAllWarningText')}
				/>
			)}
		</ScreenContainer>
	);
}

export default Schools;
