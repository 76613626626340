export type ImportError = { element: any; error: string };

export type StateType<T extends Object = any> = {
	importing: boolean;
	error: string | null;
	warning: string | null;
	imported: boolean;
	file: File | null;
	response: ParseCsvFileResult<T> | null;
	errors: ImportError[];
};

export const defaultState: StateType = {
	importing: false,
	error: null,
	warning: null,
	imported: false,
	file: null,
	response: null,
	errors: [],
};

type SetFile = {
	type: 'SetFile';
	payload: File | null;
};

type Reset = {
	type: 'Reset';
};

type SetImporting = {
	type: 'SetImporting';
	payload: boolean;
};

type SetImported = {
	type: 'SetImported';
	payload: boolean;
};

type SetError = {
	type: 'SetError';
	payload: string | null;
};

type SetErrors = {
	type: 'SetErrors';
	payload: ImportError[];
};

type SetWarning = {
	type: 'SetWarning';
	payload: string | null | undefined;
};

type SetResponse<T extends Object = any> = {
	type: 'SetResponse';
	payload: ParseCsvFileResult<T>;
};

type Actions = SetImporting | Reset | SetImported | SetError | SetWarning | SetFile | SetResponse | SetErrors;

export const reducer = (state: StateType, action: Actions): StateType => {
	switch (action.type) {
		case 'SetFile':
			return {
				...state,
				importing: false,
				error: null,
				imported: false,
				file: action.payload,
			};
		case 'SetImported':
			return { ...state, imported: action.payload, error: null, importing: false };
		case 'SetError':
			return { ...state, error: action.payload, imported: false, importing: false };
		case 'SetErrors':
			return { ...state, errors: action.payload };
		case 'SetWarning':
			return !action.payload ? { ...state, warning: null } : { ...state, warning: action.payload };
		case 'SetImporting':
			return { ...state, importing: action.payload };
		case 'Reset':
			return defaultState;
		case 'SetResponse':
			return { ...state, response: action.payload };
	}
};

export function isImportErrors(data: any): data is ImportError[] {
	return (
		Array.isArray(data) && data.every((el) => !!el && typeof el.error === 'string' && typeof el.element === 'object')
	);
}
