import { Box, IconButton } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import ConfirmationDialog from 'components/ConfirmationDialog';
import TooltipButton from 'components/TooltipButton';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';

export type DeleteTooltipProps = {
	onDelete: () => void | Promise<void>;
	amount: number;
	title?: string;
	description?: string;
};

const DeleteTooltip = ({ onDelete, amount: count, ...props }: DeleteTooltipProps) => {
	const { t } = useTranslation();

	const title = `${props.title || t('common:deleteTitle', { count })}`;

	const description = `${props.description || t('common:deleteText', { count })}`;

	const [confirm, setConfirm] = useState<boolean>(false);

	function onTooltipClick() {
		setConfirm(true);
	}

	function onClose(confirmed: boolean) {
		setConfirm(false);
		if (confirmed) {
			onDelete();
		}
	}

	return (
		<Box>
			<TooltipButton title={title as string}>
				<IconButton aria-label={title} onClick={onTooltipClick}>
					<DeleteIcon />
				</IconButton>
			</TooltipButton>
			{confirm && <ConfirmationDialog title={title} description={description} onClose={onClose} />}
		</Box>
	);
};

export default DeleteTooltip;
