import { SUPPORTED_LANGUAGES } from 'config/constants';

import { useMemo } from 'react';

import useProfile from './useProfile';

function useSelectedLanguage() {
	const profile = useProfile();

	const { language } = profile || {};

	const browserLanguage = useMemo(() => getBrowserLanguage(), []);

	const selectedLanguage = language || browserLanguage;

	const userLanguage = useMemo(() => selectedLanguage, [selectedLanguage]);

	return userLanguage;
}

export default useSelectedLanguage;

function getBrowserLanguage(): SupportedLanguage {
	let lang = window.navigator.languages ? window.navigator.languages[0] : null;
	lang =
		lang ||
		window.navigator.language ||
		(window.navigator as any).browserLanguage ||
		(window.navigator as any).userLanguage;
	let shortLang = lang || 'es';
	if (shortLang.indexOf('-') !== -1) {
		shortLang = shortLang.split('-')[0];
	}
	if (shortLang.indexOf('_') !== -1) {
		shortLang = shortLang.split('_')[0];
	}
	return isSupportedLanguage(shortLang) ? shortLang : 'es';
}

function isSupportedLanguage(data: string): data is SupportedLanguage {
	return (SUPPORTED_LANGUAGES as string[]).includes(data);
}
