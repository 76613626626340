import { useTranslation } from 'react-i18next';
import { createStyles, makeStyles, Typography } from '@material-ui/core';

type Props = {
	amount: number;
};

const DeletionTypography = ({ amount }: Props) => {
	const { t } = useTranslation();
	const classes = useStyles();

	return (
		<Typography className={classes.title} color="inherit" variant="subtitle1" component="div">
			{amount} {t('common:selected')}
		</Typography>
	);
};

const useStyles = makeStyles(() =>
	createStyles({
		title: {
			flex: '1 1 100%',
		},
	})
);

export default DeletionTypography;
