import { IconButton } from '@material-ui/core';
import ReloadIcon from '@material-ui/icons/Replay';
import TooltipButton from 'components/TooltipButton';
import { useTranslation } from 'react-i18next';

const DeleteTooltip = ({ onClick }: { onClick?: () => void | Promise<void> }) => {
	const { t } = useTranslation();

	return (
		<TooltipButton title={t('common:reload') as string}>
			<IconButton aria-label={t('common:reload')} onClick={onClick}>
				<ReloadIcon />
			</IconButton>
		</TooltipButton>
	);
};

export default DeleteTooltip;
