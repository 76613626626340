import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

function useDrawer() {
	const drawer = useSelector((store: RootState) => ({
		open: store.drawer.open,
		selectedItem: store.drawer.selectedItem,
	}));

	return drawer;
}

export default useDrawer;
