import { updateProfile } from 'lib/models/profile';
import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'store/Store';

function useUpdateLanguage() {
	const dispatch = useDispatch<AppDispatch>();

	const updateLanguage = useCallback(
		async (payload: SupportedLanguage) => {
			const updatedProfile = await updateProfile({ language: payload });
			if (updatedProfile) {
				dispatch({ type: 'updateLanguage', payload });
			}
		},
		[dispatch]
	);

	return updateLanguage;
}

export default useUpdateLanguage;
