import { CircularProgress, createStyles, makeStyles, Typography } from '@material-ui/core';

import PaperContainer from 'components/containers/PaperContainer';
import { useTranslation } from 'react-i18next';

function LoadingLayout() {
	const classes = useStyles();
	const { t } = useTranslation();
	return (
		<PaperContainer className={classes.root}>
			<CircularProgress />
			<Typography variant="caption" display="block" gutterBottom className={classes.text}>
				{t('common:loading')}
			</Typography>
		</PaperContainer>
	);
}

export default LoadingLayout;

const useStyles = makeStyles((theme) =>
	createStyles({
		root: {
			height: '100vh',
			display: 'flex',
			flexDirection: 'column',
			justifyContent: 'center',
			alignItems: 'center',
		},
		text: {
			marginTop: theme.spacing(1),
		},
	})
);
