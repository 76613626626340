import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

function useTokenHasExpired() {
	const { expired } = useSelector(({ session: { expired } }: RootState) => ({
		expired,
	}));

	return expired;
}

export default useTokenHasExpired;
