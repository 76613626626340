import { useHistory } from 'react-router-dom';
import UserTable from 'components/UserTable';
import { CustomTooltipProps } from 'components/tooltips/CustomTooltip/CustomTooltip';

type Props = {
	users: User[];
	loading: boolean;
	editUrl?: string;
	customAction?: CustomTooltipProps;
	onSelect?: (selected: User) => any;
};

function UserList({ editUrl = 'users', customAction, onSelect, ...props }: Props) {
	const history = useHistory();

	const edit = (selected: User) => {
		const { _id } = selected;
		history.push(`/${editUrl}/${_id}`);
	};

	return <UserTable {...props} onSelect={onSelect || edit} customAction={customAction} />;
}

export default UserList;
