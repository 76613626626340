const common = {
	// Common
	username: 'Username',
	password: 'Password',
	firstName: 'First name',
	lastName: 'Last name',
	email: 'Email',
	documentNumber: 'ID number',
	document: 'Document',
	fileNumber: 'File number',
	dateFrom: 'Date from',
	dateTo: 'Date to',
	accept: 'Accept',
	action: 'Action',
	add: 'Add',
	back: 'Back',
	cancel: 'Cancel',
	close: 'Close',
	create: 'Create',
	delete: 'Delete',
	duration: 'Duration',
	edit: 'Edit',
	filter: 'Filter',
	filtered: 'filtered',
	general: 'General',
	go: 'Go',
	hide: 'Hide',
	list: 'List',
	new: 'New',
	next: 'Next',
	previous: 'Previous',
	reload: 'Reload',
	remove: 'Remove',
	save: 'Save',
	search: 'Search',
	select: 'Select',
	selected: 'selected',
	selectDate: 'Select date',
	selectYear: 'Select year',
	selectMonth: 'Select month',
	selectDates: 'Select dates',
	selectEmployee: 'Select employee',
	to: 'to',
	upload: 'Upload',
	fieldMessage: 'Complete field ({{field}})',
	requiredField: '({{field}}) is required',
	invalidValue: 'Invalid value',
	invalidType: 'Invalid type',
	sunday: 'sunday',
	monday: 'monday',
	tuesday: 'tuesday',
	wednesday: 'wednesday',
	thursday: 'thursday',
	friday: 'friday',
	saturday: 'saturday',
	month: 'month',
	year: 'year',
	week: 'week',
	week_plural: 'weeks',
	day: 'day',
	day_plural: 'days',
	hour: 'hour',
	hour_plural: 'hours',
	minute: 'minute',
	minute_plural: 'minutes',
	success: 'Saved',
	download: 'Download',
	process: 'Process',
	uploadError: 'There was a problem with the file, clean data and try again',
	uploadSuccess: 'File uploaded',
	densePadding: 'Dense row',
	resetUpload: 'Clean data',
	month_1: 'January',
	month_2: 'February',
	month_3: 'March',
	month_4: 'April',
	month_5: 'May',
	month_6: 'June',
	month_7: 'July',
	month_8: 'August',
	month_9: 'September',
	month_10: 'October',
	month_11: 'November',
	month_12: 'December',
	vacations: 'vacations',
	notImplemented: 'Functionality not implemented',
	rowsPerPage: 'Rows per page',
	sendEmail: 'Send email',
	description: 'Description',
	findAnAgent: 'Find an employee ...',
	pickAnAgent: 'Select an employee',
	pickDates: 'Select dates',
	setObservations: 'Add observations',
	cuil: 'CUIL',
	address: 'Address',
	telephone: 'Telephone',
	alternateEmail: 'Alternate Email',
	multipleDates: 'Multiple dates',
	days: 'Days',
	import: 'Import',
	importingPleaseWait: 'Please wait, importing...',
	all: 'All',
	amount: 'Amount',
	without: 'Without {{element}}',
	more: 'More',
	code: 'Code',
	name: 'Name',
	levels: 'Levels',
	loading: 'Loading, please wait...',
	role: 'Role',
	role_admin: 'Administrator',
	role_panel: 'Panel user',
	role_teacher: 'Teacher',
	role_teacherAdmin: 'Teacher (administrator)',
	role_user: 'Read only',
	active: 'Active',
	isactive: 'Active',
	inactive: 'Inactive',
	invited: 'Invitation sent',
	selectCSVFile: 'Select CSV File',
	selectZIPFile: 'Select zip file',
	selectFile: 'Select file',
	fileError: 'File format not supported',
	importSuccess: 'File imported successfully',
	position: 'Position',
	googleMaps: 'Google maps',
	language: 'language',
	spanish: 'Spanish',
	english: 'English',
	valueFrom: 'From',
	valueTo: 'Up to',
	$lte: 'Less than or equal to',
	$gte: 'Greater than or equal to',
	backOfficeDocumentationTitle: 'Panel documentation',
	backOfficeDocumentationDescription: 'Panel usage documentation link',
	itDocumentationTitle: 'IT documentation',
	itDocumentationDescription: 'Server configuration documentation link',
	welcome:
		'Welcome {{name}}, if you need help with the panel or some server configuration you can use the links below.',
	dates: 'Date ranges',
	ranges: 'Ranges',
	export: 'Export',
	error: 'error',
	idle: 'idle',
	running: 'running',
	waiting: 'listening',
	withoutData: 'without data',
	tokenExpired: 'Your session has expired, please login again',
	serverIsDown: 'The API server is down, please contact IT team for more info',
	historic: 'Historic',
	followingElementsWithError:
		'The following elements with column "{{key}}" ({{elements}}) could not be created or updated, error: {{error}}',
	update_inactive: 'Invite',
	update_invited: 'Resend',
	update_again: 'Error, try again',
	level: 'Level',
	school: 'School',
	draft: 'Draft',
	'pending approval': 'Pending approval',
	approved: 'Approved (by school admin)',
	kelApproved: 'Approved (by Kel)',
	status: 'Status',
	deleteText: 'Are you sure you want to delete the selected element?',
	exact: 'exact',
	cancelDescription: "Are you sure you wan't to return to login screen?",
	message: 'Message (sent by {{email}})',
	noMessage: 'No message',
	refresh: 'Refresh',
	refreshWarning: 'Refresh (not all elements were obtained please refresh again)',
	province: 'State',
	exampleFile: 'Download an example .csv file',
	oldest: 'Oldest date',
	createdAt: 'Created',
	updatedAt: 'Updated',
} as const;

export default common;
