import { isFirstTime as fetch } from 'lib/api';
import { useMemo } from 'react';
import useElement from 'hooks/useElement';

function useIsFirstTime() {
	const { element } = useElement(fetch);

	const isFirstTime = useMemo(() => {
		return element || false;
	}, [element]);

	return isFirstTime;
}

export default useIsFirstTime;
