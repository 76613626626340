import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'store/Store';

function useLogin() {
	const dispatch = useDispatch<AppDispatch>();

	const logout = useCallback(
		(response: Session) => {
			dispatch({ type: 'login', response });
		},
		[dispatch]
	);

	return logout;
}

export default useLogin;
