import { ReactNode } from 'react';
import { Box, createStyles, makeStyles, Theme } from '@material-ui/core';

type Props = {
	children?: ReactNode | undefined;
	title?: ReactNode;
	disabled?: boolean;
	fullWidth?: boolean;
	className?: string;
};

function InputContainer({ children, title, disabled, fullWidth, className }: Props) {
	const classes = useStyles();

	const boxClassNames = disabled ? `${classes.container} ${classes.disabled}` : classes.container;
	const titleClassNames = disabled ? `${classes.title} ${classes.titleDisabled}` : classes.title;

	return (
		<Box className={fullWidth ? `${boxClassNames} ${classes.fullWidth} ${className}` : `${boxClassNames} ${className}`}>
			{!!title && <Box className={titleClassNames}>{title}</Box>}
			{children}
		</Box>
	);
}

export default InputContainer;

const useStyles = makeStyles((theme: Theme) => {
	return createStyles({
		container: {
			borderWidth: 1,
			borderStyle: 'solid',
			borderColor: theme.palette.divider,
			borderRadius: theme.spacing(0.5),
			padding: theme.spacing(1),
			marginBottom: theme.spacing(2),
			position: 'relative',
			paddingTop: theme.spacing(2),
		},
		title: {
			position: 'absolute',
			top: 0,
			zIndex: 10,
			backgroundColor: 'white',
			paddingLeft: theme.spacing(1),
			paddingRight: theme.spacing(1),
			color: theme.palette.text.secondary,
			fontSize: '1rem',
			fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
			fontWeight: 400,
			lineHeight: 1,
			letterSpacing: '0.00938em',
			transform: 'translate(0px, -6px) scale(0.85)',
		},
		disabled: {
			color: theme.palette.text.disabled,
		},
		titleDisabled: {
			color: theme.palette.text.disabled,
		},
		fullWidth: {
			width: '100%',
		},
	});
});
