// import { defaultSession, getSocket, removeSocket, storedSession } from 'lib/session';
import { defaultSession, storedSession } from 'lib/session';

export type LoginAction = {
	type: 'login';
	response: Session;
};

export type UpdateLanguage = {
	type: 'updateLanguage';
	payload: SupportedLanguage;
};

export type UpdateProfile = {
	type: 'updateProfile';
	payload: {
		language: SupportedLanguage;
	};
};

export type TokenExpired = {
	type: 'tokenExpired';
};

export type LogoutAction = {
	type: 'logout';
};

export type ServerDown = {
	type: 'serverDown';
	payload: boolean;
};

type Actions = LoginAction | LogoutAction | UpdateLanguage | UpdateProfile | TokenExpired | ServerDown;

const reducer = (state: Session = storedSession || defaultSession, action: Actions): Session => {
	switch (action.type) {
		case 'updateLanguage':
			const profileLanguage: Profile = state.profile
				? { ...state.profile, language: action.payload }
				: { language: action.payload };
			return { ...state, profile: profileLanguage };
		case 'updateProfile':
			const profile: Profile = state.profile ? { ...state.profile, ...action.payload } : { ...action.payload };
			return { ...state, profile };
		case 'login':
			const newSession = { ...action.response };
			// const ws = getSocket(action.response.token);
			return { ...state, ...newSession, ws: null, expired: false, serverDown: false };
		case 'tokenExpired':
			// removeSocket();
			return { ...defaultSession, expired: true };
		case 'logout':
			// removeSocket();
			return defaultSession;
		case 'serverDown':
			return { ...state, serverDown: action.payload };

		default:
			return state;
	}
};

export default reducer;
