const users = {
	notFound: 'Usuario {{user}} no encontrado',
	emptyImportFile: 'El archivo importado está vacio',
	deleteTitle: 'Eliminación de usuarios',
	deleteText: '¿Está seguro que desea eliminar al usuario seleccionado?',
	deleteText_plural: '¿Está seguro que desea eliminar a los usuarios seleccionados?',
	confirmImportTitle: 'Confirmar importación',
	confirmImportDescription: 'Se importarán {{totalToImport}} de los {{amountOfUsers}} usuarios procesados',
	importedDescription: '{{importedAmount}} usuarios importados correctamente',
	assignedUser: 'Usuario ({{fullName}}) con documento ({{documentNumber}})',
	assignedUsers: 'Usuarios asignados ({{amount}})',
	unassignedUser: 'Usuario ({{fullName}}) con documento ({{documentNumber}})',
	unassignedUsers: 'Usuarios no asignados  ({{amount}})',
	invalidUser: 'Usuario en fila ({{index}})',
	invalidUsers: 'Usuarios invalidos ({{amount}})',
	importDescription:
		'Subir un único archivo CSV, el sistema intentará identificar automáticamente los números de documento. En el caso de que no sea posible se le pedirá seleccionar uno de la base de datos.',
	importDescriptionWarning: 'Todos los usuarios a importar que ya se encuentren en la base de datos serán modificados.',
	invalidFile: 'Archivo inválido, columnas con nombre "nombre", "apellido", "documento" y "email" son necesarias',
	invalidDocument: 'El documento debe poseer el formato xx-xxxxxxxx-x',
	uploadUsersError: 'Los siguientes usuarios no fueron modificados ({{users}})',
	uploadUsersSuccess: 'Todos los usuarios fueron importados correctamente',
	createUser: 'Crear usuario',
	resetPassword: 'Blanquear contraseña',
	resetPasswordTitle: 'Blanquear contraseña',
	resetPasswordText: '¿Desea blanquear la contraseña del usuario seleccionado?',
	creationSuccessTitle: 'Usuario creado',
	creationSuccessDescription:
		'El usuario de "{{first_name}} {{last_name}}" fué creado con éxito, ahora "{{first_name}}" puede acceder al panel utilizando el nombre de usuario "{{username}}" y una contraseña de su elección.',
	creationPasswordText:
		'La primera vez que el usuario inicie sesión creará su contraseña la cual será utilizada en los acesos siguientes.',
	confirmTeachersSaveTitle: 'Guardado de datos en Hubspot',
	confirmTeachersSaveDescription:
		'La modificación de estos datos también impactaran en la base de datos de Hubspot, este proceso consumirá parte de su cuota de acceso al sistema (¿Está seguro que desea continuar?)',
};

export default users;
