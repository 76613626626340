import React from 'react';

import { Box, createStyles, Fab, Input, makeStyles } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';

type UploadInputProps = {
	fileName: string;
	onChange(event: React.ChangeEvent<HTMLInputElement>): void;
	id: string;
	name: string;
	disabled?: boolean;
	actionDisabled?: boolean;
	autoFocus?: boolean;
	accept?: string;
};

const UploadInput = ({
	id,
	name,
	fileName,
	onChange,
	disabled,
	actionDisabled,
	autoFocus,
	accept,
}: UploadInputProps) => {
	const classes = useStyles();
	return (
		<label htmlFor={id} className={classes.inputContainer}>
			<input
				className={classes.inputHide}
				id={id}
				name={name}
				type="file"
				onChange={onChange}
				disabled={disabled}
				autoFocus={autoFocus}
				accept={accept}
			/>
			<Box>
				<Fab
					className={classes.fab}
					color="secondary"
					size="small"
					component="span"
					aria-label="add"
					variant="round"
					disabled={actionDisabled}
				>
					<AddIcon />
				</Fab>
				<Input className={classes.input} value={fileName} margin="dense" id="name" disabled={true} />
			</Box>
		</label>
	);
};
const useStyles = makeStyles((theme) =>
	createStyles({
		inputContainer: {
			flex: 1,
			display: 'flex',
			// minWidth: theme.spacing(50),
		},
		fab: {
			margin: theme.spacing(2),
		},
		input: {
			width: '75%',
			maxWidth: theme.spacing(80),
			margin: theme.spacing(2),
			[theme.breakpoints.up('md')]: {
				minWidth: theme.spacing(50),
			},
			[theme.breakpoints.down('md')]: {
				minWidth: theme.spacing(15),
			},
		},
		box: {
			flex: 1,
		},
		inputHide: {
			display: 'none',
		},
	})
);

export default UploadInput;
