import { useCallback, useState } from 'react';

import { fetchItems } from 'lib/models/items';

const defaultSearch: ItemsSearchParameters = {
	limit: 20,
	offset: 0,
	sortBy: 'edition',
	sortOrder: 'desc',
	filters: {
		isRecommended: false,
	},
};

function useItems() {
	const [loading, setLoading] = useState<boolean>(true);
	const [items, setItems] = useState<Item[]>([]);
	const [count, setCount] = useState(0);

	const refetch = useCallback(async (search: ItemsSearchParameters = defaultSearch) => {
		setLoading(true);
		const response = await fetchItems(search);
		setItems(response.data);
		setCount(response.count);
		setLoading(false);
	}, []);

	return { loading, items, count, refetch };
}

type UseItems = ReturnType<typeof useItems>;

export type FetchItemsMethod = UseItems['refetch'];

export default useItems;
