import { useHistory } from 'react-router-dom';
import ListTable from 'components/ListTable';

type Props = {};

function ListsList(props: Props) {
	const history = useHistory();

	const edit = (selected: List) => {
		const { _id } = selected;
		history.push(`/lists/${_id}`);
	};

	return <ListTable {...props} onSelect={edit} />;
}

export default ListsList;
