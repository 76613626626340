import { IconButton, SvgIconTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import TooltipButton from 'components/TooltipButton';

export type CustomTooltipProps = {
	onClick?: () => void | Promise<void>;
	icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
	title: string;
	['aria-label']: string;
	disabled?: boolean;
};

const CustomTooltip = ({ icon: Icon, disabled, ...props }: CustomTooltipProps) => {
	return (
		<TooltipButton title={props.title}>
			<IconButton aria-label={props['aria-label']} onClick={disabled ? undefined : props.onClick} disabled={disabled}>
				<Icon color={disabled ? 'disabled' : undefined} />
			</IconButton>
		</TooltipButton>
	);
};

export default CustomTooltip;
