import { useTranslation } from 'react-i18next';

import { ListItemText, TableHead, TableRow, TableCell, Typography, Box } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import ResponsiveTable from 'components/ResponsiveTable';

import moment, { Moment } from 'moment';
import { ListStatus } from 'teachers-types';

type Props = {
	lists: List[];
	loading: boolean;
	status: ListStatus;
	onSchoolSelected: (hubspotSchoolId: string) => any;
	schools: School[];
};

type SchoolLists = {
	id: string;
	name: string;
	lists: List[];
	amount: number;
	oldest: Moment;
};

function Schools({ loading, lists, onSchoolSelected, status, schools: schoolsData }: Props) {
	const { t } = useTranslation();

	const classes = useStyles();

	const schools = lists.reduce((all, list) => {
		const school = schoolsData.find((current) => current.hubspotId === list.schoolHubspotId);
		const index = all.findIndex((current) => current.id === list.schoolHubspotId);
		if (index >= 0) {
			all[index]!.lists.push(list);
			all[index]!.amount = all[index]!.lists.reduce((amount, list) => {
				return amount + (list.status === status ? 1 : 0);
			}, 0);
			all[index]!.oldest = all[index]!.lists.reduce((oldest, list) => {
				const date = moment(list.createdAt);
				return date.isBefore(oldest) ? date : oldest;
			}, all[index]!.oldest);
			return all;
		}
		const schoolList: SchoolLists = {
			id: list.schoolHubspotId || '',
			name: school?.name ?? (list.schoolName || ''),
			lists: [list],
			amount: list.status === status ? 1 : 0,
			oldest: moment(list.createdAt),
		};
		return [...all, schoolList];
	}, [] as SchoolLists[]);

	return (
		<ResponsiveTable
			loading={loading}
			elements={schools}
			list={{
				primaryKey: 'id',
				renderListItemText: (school) => {
					return (
						<ListItemText
							onClick={() => onSchoolSelected(school.id)}
							primary={`${school.name}`}
							secondary={
								<Box>
									<Typography
										className={
											status === 'pending approval'
												? classes.pending
												: status === 'draft'
												? classes.draft
												: status === 'approved'
												? classes.approved
												: classes.kelApproved
										}
									>
										{t(`common:${status}`)}: {school.amount}
									</Typography>
									<Typography>
										{t('common:oldest')}: {school.oldest.format('DD/MM/YYYY HH:mm')}
									</Typography>
								</Box>
							}
						/>
					);
				},
			}}
			table={{
				renderHead: () => (
					<TableHead>
						<TableRow>
							<TableCell align="left" padding="normal">
								{t('common:school')}
							</TableCell>
							<TableCell align="left" padding="normal">
								{t(`common:${status}`)}
							</TableCell>
							<TableCell align="left" padding="normal">
								{t('common:oldest')}
							</TableCell>
						</TableRow>
					</TableHead>
				),
				renderRow: (school) => {
					return (
						<TableRow
							hover
							tabIndex={-1}
							key={school.id}
							className={classes.row}
							onClick={() => onSchoolSelected(school.id)}
						>
							<TableCell>{school.name}</TableCell>
							<TableCell
								className={
									status === 'pending approval'
										? classes.pending
										: status === 'draft'
										? classes.draft
										: status === 'approved'
										? classes.approved
										: classes.kelApproved
								}
							>
								{school.amount}
							</TableCell>
							<TableCell>{school.oldest.format('DD/MM/YYYY HH:mm')}</TableCell>
						</TableRow>
					);
				},
			}}
		/>
	);
}

export default Schools;

const useStyles = makeStyles((theme) =>
	createStyles({
		row: {
			cursor: 'pointer',
		},
		pending: {
			backgroundColor: theme.palette.error.main,
			color: 'white',
		},
		draft: {
			backgroundColor: theme.palette.action.disabled,
			color: 'white',
		},
		approved: {
			backgroundColor: theme.palette.warning.main,
			color: 'white',
		},
		kelApproved: {
			backgroundColor: theme.palette.primary.main,
			color: 'white',
		},
	})
);
