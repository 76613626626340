import { Select, FormControl, InputLabel, MenuItem, makeStyles, createStyles } from '@material-ui/core';
import useValues from 'hooks/useValues';
import { useTranslation } from 'react-i18next';

type Props = {
	filterKey: keyof ItemsSearchFilters;
	filters: ItemsSearchFilters;
	setFilter: (ev: React.ChangeEvent<{ value: unknown }>, key: keyof ItemsSearchFilters) => any;
};

function SearchItem({ filters, filterKey: key, setFilter }: Props) {
	const classes = useStyles();
	const { t } = useTranslation();

	const values = useValues(key);

	return (
		<FormControl fullWidth className={classes.searchItem} variant="outlined">
			<InputLabel id={`${key}-label`}>{t(`items:${key}`)}</InputLabel>

			<Select
				labelId={`${key}-label`}
				id={`${key}`}
				value={getValue(filters, key)}
				label={t(`items:${key}`)}
				onChange={(ev) => setFilter(ev, key)}
			>
				{values.map((el) => {
					return (
						<MenuItem key={`${key}-${el}`} value={getItemValue(el)}>
							{getMenuItemLabel(el)}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
}

export default SearchItem;

function getValue(filters: ItemsSearchFilters, key: keyof ItemsSearchFilters): string | undefined {
	if (Array.isArray(filters[key])) {
		return (filters[key] as any[])[0];
	}
	if (typeof filters[key] === 'boolean') {
		return filters[key] ? 'true' : 'false';
	}
	return `${filters[key]}`;
}

function getItemValue(value: any): string {
	if (typeof value === 'boolean') {
		return value ? 'true' : 'false';
	}
	return `${value}`;
}

function getMenuItemLabel(label: string): string {
	if (label !== 'true' && label !== 'false') {
		return label;
	}
	return label === 'true' ? 'Yes' : 'No';
}

const useStyles = makeStyles((theme) =>
	createStyles({
		searchItem: {
			maxWidth: '200px',
			marginLeft: theme.spacing(1),
			marginRight: theme.spacing(1),
			marginBottom: theme.spacing(1),
		},
	})
);
