import { API_DOMAIN, LISTS_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';
import { ListStatus } from 'teachers-types';

export async function fetchLists(): Promise<List[]> {
	try {
		const response = await api.get<List[]>(`${API_DOMAIN}${LISTS_URL}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function searchLists(schoolHubspotId: string, status: ListStatus): Promise<List[]> {
	try {
		const response = await api.get<List[]>(`${API_DOMAIN}${LISTS_URL}/search`, {
			params: {
				filter: {
					schoolHubspotId,
					status,
				},
			},
		});

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return [];
	}
}

export async function fetchList(id: string): Promise<List | null> {
	try {
		const response = await api.get<List | null>(`${API_DOMAIN}${LISTS_URL}/${id}`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return null;
	}
}

export async function updateList(list: List): Promise<List | List[]> {
	if (!list._id) {
		delete list._id;
	}

	const url = list._id ? `${API_DOMAIN}${LISTS_URL}/${list._id}` : `${API_DOMAIN}${LISTS_URL}`;

	const response = await (list._id ? api.patch<List>(url, list) : api.post<List[]>(url, list));

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || list;
}

export async function deleteList(list: List): Promise<List> {
	const url = `${API_DOMAIN}${LISTS_URL}/${list._id}`;

	const response = await api.delete<List>(url);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || list;
}
