import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { createStyles, Input, makeStyles, Box, Typography, Chip } from '@material-ui/core';
import { DeleteTooltipProps } from 'components/tooltips/DeleteTooltip/DeleteTooltip';
import moment from 'moment';
import { CustomTooltipProps } from 'components/tooltips/CustomTooltip/CustomTooltip';

type CommonProps = {
	toggle: () => void | Promise<void>;
	visible: boolean;
};

export type AdvanceSearchForm<T> = CommonProps & {
	values: T | T[];
	remove: (value: T) => void | Promise<void>;
	iconPosition?: 'right' | 'left';
};

type Search = (value: string) => void | Promise<void>;

export type SearchProps<T> = {
	disabled?: boolean;
	deletion?: DeleteTooltipProps | null;
	live?: boolean;
	onSearch?: Search;
	onRefetch?: () => void | Promise<void>;
	onExport?: () => void | Promise<void>;
	advanceSearch?: AdvanceSearchForm<T>;
	customAction?: CustomTooltipProps;
	placeholder?: string;
};

function SearchTypography<T>({
	placeholder,
	disabled = false,
	live = false,
	onSearch,
	advanceSearch: searchForm,
}: SearchProps<T>) {
	const { t } = useTranslation();
	const classes = useStyles();

	const [search, setSearch] = useState<string>('');
	const [currentSearch, setCurrentSearch] = useState<string>('');

	const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		const value = `${event.target.value || ''}`;
		setSearch(value);
		if (live) {
			callOnSearch(value);
		}
	};

	function submitSearch() {
		callOnSearch(search);
		setCurrentSearch(search);
		setSearch('');
	}

	function handleSearchRemove() {
		setSearch('');
		setCurrentSearch('');
		callOnSearch('');
	}

	function callOnSearch(value: string) {
		onSearch?.(value.trim());
	}

	const selectedValues = searchForm ? (Array.isArray(searchForm.values) ? searchForm.values : [searchForm.values]) : [];

	return (
		<Typography className={classes.title} variant="h6" id="tableTitle" component="div">
			<Box className={classes.inputContainer}>
				{selectedValues.map((element) => {
					return (
						!!element && (
							<Chip
								className={classes.chip}
								label={getLabel(element)}
								onDelete={() => searchForm?.remove(element)}
								color="primary"
							/>
						)
					);
				})}
				{!!currentSearch && (
					<Chip className={classes.chip} label={currentSearch} onDelete={handleSearchRemove} color="primary" />
				)}

				{!!onSearch && (
					<Input
						disabled={disabled}
						autoFocus
						margin="dense"
						fullWidth
						placeholder={placeholder || t('common:search')}
						onChange={onChange}
						className={classes.input}
						value={search}
						onKeyPress={(event) => {
							if (event.key === 'Enter') {
								submitSearch();
							}
						}}
					></Input>
				)}
			</Box>
		</Typography>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		fullWidth: {
			width: '100%',
		},
		inputContainer: {
			display: 'flex',
			flexDirection: 'row',
		},
		chip: {
			marginRight: theme.spacing(1),
		},
		input: {
			marginRight: theme.spacing(2),
		},
		title: {
			flex: '1 1 100%',
		},
	})
);

export default SearchTypography;

function getLabel(element: any): string {
	if (typeof element === 'string') {
		return element;
	}
	if (typeof element === 'object') {
		if (isString(element.label)) return element.label;
		if (isString(element.code)) return element.code;
		if (isString(element.description)) return element.description;
		if (isString(element.title)) return element.title;
		if (isString(element.subtitle)) return element.title;
		if (isString(element.name)) return element.name;
		if (isString(element.value)) return element.value;
		if (isString(element._id)) return element._id;
		const key = Object.keys(element).find((key) => isString(element[key]));
		if (key) return element[key] as string;
	}
	if (moment.isMoment(element)) {
		return element.toISOString();
	}
	return 'without string value';
}

function isString(data: any): data is string {
	return typeof data === 'string';
}
