import { searchLists } from 'lib/models/lists';
import { useCallback, useEffect, useState } from 'react';
import { ListStatus } from 'teachers-types';

function useLists(school: string | null, status: ListStatus) {
	const [loading, setLoading] = useState<boolean>(false);
	const [lists, setLists] = useState<List[]>([]);

	const refetch = useCallback(async () => {
		setLoading(true);
		const response = await searchLists(school || '', status);
		setLists(response);
		setLoading(false);
	}, [school, status]);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { loading, lists, refetch };
}

export default useLists;
