import { fetchProfile } from 'lib/models/profile';
import { useEffect, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';
import useElement from './useElement';

function useProfile() {
	const { profile: loggedProfile, logged } = useSelector(({ session: { profile, logged } }: RootState) => ({
		profile,
		logged,
	}));

	const { element: networkProfile, loading, refetch } = useElement(fetchProfile);

	const withProfile = !loading && !!networkProfile;

	const withError = !loading && !!networkProfile && !isProfile(networkProfile);

	const profile = useMemo(() => {
		return loggedProfile || (isProfile(networkProfile) ? networkProfile : null);
	}, [loggedProfile, networkProfile]);

	useEffect(() => {
		if (logged && !withProfile && !withError) {
			refetch();
		}
	}, [withProfile, withError, logged, refetch]);

	return profile;
}

export default useProfile;

function isProfile(
	data:
		| Profile
		| {
				error: true;
		  }
		| null
): data is Profile {
	return !!data && !(data as any).error;
}
