// import { API_DOMAIN } from 'config/constants';
import { UsersData } from 'config/data';
// import { nanoid } from 'nanoid';
import { setToken as setApiToken } from './api';
// import * as io from 'socket.io-client';

// let ws: Websocket | null = null;

export enum StorageKey {
	'session' = 'session',
	'users' = 'users',
}

type ValidType = UsersData[] | Session;

export const getDate = (fromTime?: number) => {
	const date = fromTime ? new Date(fromTime) : new Date();

	const year = date.getFullYear();
	const month = date.getUTCMonth();
	const day = date.getUTCDay();
	const time = `${date.getHours()}.${date.getMinutes()}.${date.getSeconds()}`;
	const dateString = `${year}/${month}/${day} ${time.replace('.', ':')}`;

	return { dateString, year, month, day, time };
};

export const setItem = (key: StorageKey, data: ValidType) => {
	try {
		const dataString = JSON.stringify(data);
		localStorage.setItem(key as string, dataString);
		return true;
	} catch (error) {
		console.warn(error);
	}
	return false;
};

export function getItem<T>(key: StorageKey): T | null {
	try {
		const dataString = localStorage.getItem(key as string);
		const data = dataString ? JSON.parse(dataString) : dataString;
		return data;
	} catch (error) {
		console.warn(error);
	}
	return null;
}

export function removeItem(key: StorageKey): void {
	localStorage.removeItem(key as string);
}

export const setUsers = (users: UsersData[]) => {
	const success = setItem(StorageKey.users, users);
	return success;
};

export const getSession = () => {
	const session = getItem<Session>(StorageKey.session);
	if (!session || !session.token) {
		return session;
	}
	setApiToken(session.token);
	// const ws = getSocket(session.token);
	return { ...session, ws: null };
};

export const setSession = (session: Session) => {
	const success = setItem(StorageKey.session, session);
	return success;
};

export const removeSession = () => {
	removeItem(StorageKey.session);
};

// export const removeSocket = () => {
// 	if (ws) {
// 		if (ws.socket.connected) {
// 			ws.socket.disconnect();
// 		}
// 		ws = null;
// 	}
// };

// const getSocket = (token?: string): Websocket | null => {
// 	if (ws && ws.socket.connected) {
// 		return ws;
// 	}
// 	// Socket exists but is not connected
// 	if (ws) {
// 		ws = null;
// 	}
// 	if (!token) {
// 		console.warn(`token must exists to generate socket`);
// 		return null;
// 	}
// 	const socket = io.connect(API_DOMAIN, { query: `token=${token}` });
// 	return { id: nanoid(), socket };
// };
