import { useCallback, useEffect, useState } from 'react';

type Fetch<P, T = any> = (data?: T) => Promise<P | null>;

/**
 * Fetch data and return it
 *
 * @param fetch A function that will receive paginationProp and must return a valid "PaginateResponse" with an array of elements to be displayed in the list or table
 * @param props extends query search object
 
 */
function useElement<P, T>(fetch: Fetch<P, T>, props?: T) {
	const [loading, setLoading] = useState(false);
	const [data, setCurrentAPIResponse] = useState<P | null>(null);

	const getData = useCallback(async () => {
		setLoading(true);
		const requestResponse = await fetch(props);
		setCurrentAPIResponse(requestResponse);
		setLoading(false);
	}, [fetch, props]);

	const refetch = useCallback(
		(reset: boolean = false) => {
			if (reset) {
				setCurrentAPIResponse(null);
			}
			getData();
		},
		[getData]
	);

	useEffect(() => {
		refetch();
	}, [refetch]);

	return { element: data, loading, refetch };
}

export default useElement;
