import React from 'react';
import { useTranslation } from 'react-i18next';

import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import ListSubheader from '@material-ui/core/ListSubheader';
import List from '@material-ui/core/List';

import { DRAWER_WIDTH } from 'config/constants';

import DashboardItem from './MenuItems/Dashboard';

import UserItem from './MenuItems/User';
import UsersItem from './MenuItems/Users';
import SchoolItem from './MenuItems/Schools';
import TeachersItem from './MenuItems/Teachers';
import ItemsItem from './MenuItems/Items';
import ListsItem from './MenuItems/Lists';

import AuthorizedComponent from 'components/AuthorizedComponent';

import useInDev from 'hooks/useInDev';

import useDrawer from '../hooks/useDrawer';
import useDrawerActions from '../hooks/useDrawerActions';

function Menu() {
	const inDEV = useInDev();

	const classes = useStyles();
	const { t } = useTranslation();

	const { selectedItem } = useDrawer();

	const { setSelectedItem } = useDrawerActions();

	const handleListItemClick = (_event: React.MouseEvent<HTMLDivElement, MouseEvent>, index: number) => {
		setSelectedItem(index);
	};

	return (
		<List
			component="nav"
			aria-labelledby="nested-list-subheader"
			subheader={
				<ListSubheader component="div" id="nested-list-subheader">
					{t('common:general')}
				</ListSubheader>
			}
			className={classes.root}
		>
			<AuthorizedComponent
				component={() => <DashboardItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule="*"
			/>
			<AuthorizedComponent
				component={() => <UsersItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule="UserFull"
			/>
			<AuthorizedComponent
				component={() => <TeachersItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule="TeachersEdition"
			/>
			<AuthorizedComponent
				component={() => <SchoolItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule="SchoolEdition"
			/>
			<AuthorizedComponent
				component={() => <ItemsItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule="ItemsFull"
			/>
			<AuthorizedComponent
				component={() => <ListsItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule="ListsFull"
			/>

			<Divider />

			{inDEV && (
				<ListSubheader component="div" id="nested-list-subheader">
					{t('general:personal_area')}
				</ListSubheader>
			)}

			<AuthorizedComponent
				component={() => <UserItem selected={selectedItem} onSelect={handleListItemClick} />}
				rule={['Profile']}
				mode="or"
			/>
		</List>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		root: {
			width: '100%',
			maxWidth: DRAWER_WIDTH,
			backgroundColor: theme.palette.background.paper,
			paddingTop: theme.spacing(4),
		},
	})
);

export default Menu;
