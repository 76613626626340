import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import LinearProgress from '@material-ui/core/LinearProgress';

import Alert, { AlertsTypes } from 'components/Alert';
import UserList from 'components/UserList';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';
import useUsers from 'hooks/useUsers';

function Users() {
	const { t } = useTranslation();

	const { loading, users } = useUsers();

	const [error, setError] = useState<string>('');

	const history = useHistory();

	return (
		<ScreenContainer title={t('general:user_plural')}>
			<CreateIcon onCreate={() => history.push('/users/new')} />
			<Alert message={error} show={!!error} type={AlertsTypes.error} onClose={() => setError('')} />

			{!!loading && <LinearProgress />}

			<UserList users={users} loading={loading} />
		</ScreenContainer>
	);
}

export default Users;
