import { API_DOMAIN, PROFILE_URL } from 'config/constants';
import { api } from 'lib/api';
import { checkStatus } from 'lib/helpers';

export async function fetchProfile(): Promise<Profile | null | { error: true }> {
	try {
		const response = await api.get<Profile | null>(`${API_DOMAIN}${PROFILE_URL}/`);

		if (!checkStatus(response)) {
			throw new Error('invalid credentials');
		}

		return response.data;
	} catch (error) {
		console.error(error);
		return { error: true };
	}
}

export async function updateProfile(profile: Profile): Promise<Profile> {
	const url = `${API_DOMAIN}${PROFILE_URL}/`;

	const response = await api.patch<Profile>(url, profile);

	if (!checkStatus(response)) {
		throw new Error('invalid credentials');
	}

	return response.data || profile;
}
