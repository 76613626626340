import * as storage from './storage';
import rules, { RuleKeys } from 'config/rules';

// export const getSocket = storage.getSocket;

// export const removeSocket = storage.removeSocket;

export const defaultSession: Session = {
	logged: false,
	expired: false,
	serverDown: false,
};

export const storedSession: Session = storage.getSession() || {
	logged: false,
	expired: false,
	serverDown: false,
};

const ruleValidationFailed = (rules: ServerRules = {}, { name, validator }: Rule) => {
	const valid = !!rules[name] && validator(rules[name]);
	// if (!valid) {
	// 	debugger;
	// 	validator(rules[name]);
	// }

	return !valid;
};

const validRule = (ruleName: keyof typeof rules, userRules: ServerRules): boolean => {
	return (
		!rules[ruleName]?.rules.find((rule) => {
			const failed = rule.disabled || ruleValidationFailed(userRules, rule);
			// if (failed) {
			// 	console.debug(ruleName);
			// 	debugger;
			// }
			return failed;
		}) || false
	);
};

const validRules = (ruleName: RuleKeys, userRules: ServerRules): boolean => {
	return (
		// if ruleName is not received (component should be shown)
		!ruleName ||
		// User must have the "rules" key
		(userRules &&
			typeof userRules === 'object' &&
			// If one rule fails, all validation fails
			// If only one rule received, validate it
			((typeof ruleName === 'string' && validRule(ruleName as string, userRules)) ||
				// If more than one rule received, validate them
				(Array.isArray(ruleName) && !!ruleName.find((name: string) => validRule(name, userRules)))))
	);
};

export const validateRules = (ruleNames: RuleKeys, userRules: ServerRules, mode: 'and' | 'or' = 'and') => {
	if (!ruleNames || (typeof ruleNames === 'string' && ruleNames === '*')) {
		return true;
	}
	if (typeof ruleNames === 'string') {
		return validRules(ruleNames, userRules);
	}
	if (Array.isArray(ruleNames) && !ruleNames.find((rule) => typeof rule !== 'string')) {
		return mode === 'and'
			? !ruleNames.find((ruleName) => !validRules(ruleName, userRules))
			: !!ruleNames.find((ruleName) => validRules(ruleName, userRules));
	}
	return false;
};

export const validateComponent = (userRules: ServerRules | null, props: any, mode: 'and' | 'or' = 'and'): boolean => {
	return validateRules(props.rules || props.rule, userRules || {}, mode);
};
