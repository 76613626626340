import { Box, IconButton } from '@material-ui/core';
import ExportIcon from '@material-ui/icons/CloudDownload';
import TooltipButton from 'components/TooltipButton';
import { useTranslation } from 'react-i18next';

export type ExportTooltipProps = {
	onExport: () => any;
};

const ExportTooltip = ({ onExport }: ExportTooltipProps) => {
	const { t } = useTranslation();

	return (
		<Box>
			<TooltipButton title={t('common:export') as string}>
				<IconButton aria-label={t('common:export')} onClick={onExport}>
					<ExportIcon />
				</IconButton>
			</TooltipButton>
		</Box>
	);
};

export default ExportTooltip;
