const general = {
	// General
	account: 'account',
	account_plural: 'accounts',
	dashboard: 'dashboard',
	exit: 'exit',
	favorite: 'favorite',
	favorite_plural: 'favorites',
	id: 'ID',
	inbox: 'inbox',
	outbox: 'outbox',
	personal_area: 'My section',
	profile: 'profile',
	settings: 'settings',
	user: 'user',
	user_plural: 'users',
	user_me: 'my data',
	userTitle: '{{name}}',
	showTable: 'Show table',
	showCalendar: 'Show calendar',
	school: 'School',
	school_plural: 'Schools',
	createSchool: 'Create school',
	editSchool: 'Edit school',
	teacher: 'Teacher',
	teacher_plural: 'Teachers',
	item: 'Item',
	item_plural: 'Items',
	list: 'List',
	list_plural: 'List',
} as const;

export default general;
