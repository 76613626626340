import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	ListItemText,
	Typography,
	Box,
	Button,
	TextField,
	Modal,
	CircularProgress,
	List,
	ListItem,
	ListItemSecondaryAction,
	FormControl,
	InputLabel,
	Select,
	MenuItem,
} from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import { Autocomplete } from '@material-ui/lab';
import ExportIcon from '@material-ui/icons/CloudDownload';
import DownloadIcon from '@material-ui/icons/CloudDownload';
import CloseIcon from '@material-ui/icons/Close';

import Papa from 'papaparse';

import { sanitizedInput } from 'lib/helpers';

import SearchToolbar from 'components/SearchToolbar';
import FullLayout from 'components/layouts/FullLayout';
import PaperContainer from 'components/containers/PaperContainer';
import InputContainer from 'components/containers/InputContainer';

import useSearchFilters from 'hooks/useSearchFilters';
import useSchools from 'hooks/useSchools';
import useQuery from 'hooks/useQuery';
import useUpdateQuery from 'hooks/useUpdateQuery';

import SearchListItem from './SearchListItem';
import useLists from './useLists';
import { ListStatus, listStatus } from 'teachers-types';
import Lists from './Lists';
import Schools from './Schools';

const defaultFilter: ListSearchFilters = {};

type Props = {
	loading?: boolean;
	onSelect?(list: List): any;
};

const searchKeys = ['level', 'status'] as (keyof ListSearchFilters)[];

type ExportFile = { name: string; file: string };

export default function ListTable(props: Props) {
	const { t } = useTranslation();

	const classes = useStyles();

	const query = useQuery();
	const queryStatus = query.get('status');
	const [selected, setSelected] = React.useState<List[]>([]);
	const [showExportModal, setShowExportModal] = React.useState(false);
	const [exportLoading, setExportLoading] = React.useState(false);
	const [csvFiles, setCsvFiles] = React.useState<ExportFile[]>([]);
	const { loading: loadingSchools, schools } = useSchools();
	const [status, setStatus] = React.useState<ListStatus>(isValidStatus(queryStatus) ? queryStatus : 'approved');
	const [school, setSchool] = useState<string | null>(query.get('school') || null);

	const { loading: loadingLists, lists: listsSearch, refetch } = useLists(school, status);

	const loading = loadingSchools || loadingLists || exportLoading;

	const { filters, setFilter, setText, advanceSearch, resetFilters } = useSearchFilters(defaultFilter);

	const params = React.useMemo(() => ({ status, school }), [status, school]);
	useUpdateQuery(params);

	const searchValue = sanitizedInput(filters.text);

	const lists = listsSearch.filter((list) => {
		return (
			(!searchValue ||
				sanitizedInput(list.name).includes(searchValue) ||
				sanitizedInput(list.level) === searchValue ||
				sanitizedInput(list.schoolName) === searchValue) &&
			(!filters.level || list.level === filters.level) &&
			(!filters.status || list.status === filters.status)
		);
	});

	const disabled = !school || loading;

	const onSelectAllClick = useCallback(() => {
		if (selected.length === lists.length) {
			setSelected([]);
		} else {
			setSelected(lists);
		}
	}, [lists, selected.length]);

	const toggleSelected = useCallback(
		(list: List) => {
			const found = selected.find((_list) => _list._id === list._id);
			setSelected((elements) => {
				return found ? elements.filter((el) => el._id !== list._id) : [...elements, list];
			});
		},
		[selected]
	);

	const closeModal = useCallback(() => {
		setExportLoading(false);
		setShowExportModal(false);
	}, []);

	const confirmExport = useCallback(async () => {
		setExportLoading(true);
		try {
			const files = selected.map((list) => {
				return {
					name: list.name,
					file: Papa.unparse(list.items),
				};
			});
			setCsvFiles(files);
			setExportLoading(false);
			return;
		} catch (error) {
			console.warn(error);
		}
		closeModal();
	}, [closeModal, selected]);

	const updateStatus = useCallback((status: any) => {
		setStatus(status as ListStatus);
	}, []);

	const updateSchool = useCallback((school: string | null) => {
		setSchool(school);
	}, []);

	return (
		<FullLayout>
			<FormControl fullWidth variant="outlined" style={{ marginBottom: '8px', marginTop: '8px' }}>
				<InputLabel id={'status-label'}>{t('common:status')}</InputLabel>

				<Select
					labelId="status-label"
					id="status"
					value={status}
					label={t('common:status')}
					onChange={(ev) => {
						updateStatus(ev.target.value);
					}}
				>
					{listStatus.map((status) => {
						return (
							<MenuItem key={`status-${status}`} value={status}>
								{t(`common:${status}`)}
							</MenuItem>
						);
					})}
				</Select>
			</FormControl>

			{school && (
				<InputContainer title={t('common:school')} className={classes.schoolLabelContainer}>
					<Box className={classes.schoolLabel}>
						<Typography>
							{schools.find((current) => current.hubspotId === school)?.name ?? t('common:without')}
						</Typography>
						<CloseIcon onClick={() => updateSchool(null)} />
					</Box>
				</InputContainer>
			)}

			{!school && (
				<Autocomplete
					fullWidth={true}
					onChange={(_, value) => updateSchool(value?.hubspotId ?? null)}
					id="combo-box-schools"
					options={schools}
					getOptionLabel={(school: School) => school.name}
					renderInput={(params) => <TextField {...params} label={t('common:school')} variant="outlined" />}
				/>
			)}

			<SearchToolbar
				onSearch={(value) => setText(value)}
				live={true}
				advanceSearch={advanceSearch}
				disabled={disabled}
				customAction={{
					'aria-label': t('common:export'),
					title: t('common:export'),
					icon: ExportIcon,
					disabled: disabled || selected.length === 0,
					onClick: () => setShowExportModal(true),
				}}
				onRefetch={() => refetch()}
			>
				<Box className={classes.searchContainer}>
					{searchKeys.map((filterKey) => {
						return <SearchListItem key={filterKey} filterKey={filterKey} filters={filters} setFilter={setFilter} />;
					})}
					<Button
						variant="contained"
						title={t('common:clear')}
						onClick={resetFilters}
						fullWidth={true}
						color="secondary"
						className={classes.button}
					>
						<Typography>{t('common:clear')}</Typography>
					</Button>
				</Box>
			</SearchToolbar>

			{!school && (
				<Schools lists={lists} loading={loading} onSchoolSelected={setSchool} status={status} schools={schools} />
			)}

			{school && (
				<Lists
					disabled={disabled}
					loading={loading}
					lists={lists}
					onSelectAllClick={onSelectAllClick}
					selected={selected}
					toggleSelected={toggleSelected}
					onSelect={props.onSelect}
				/>
			)}

			<Modal open={showExportModal} onClose={closeModal} className={classes.modal}>
				<PaperContainer>
					<Box className={classes.modalChild}>
						<Typography variant="h4">{t('lists:exportTitle')}</Typography>
						{!csvFiles.length && <Typography variant="body2">{t('lists:exportDescription')}</Typography>}
						{csvFiles.length > 0 && (
							<List>
								{csvFiles.map((csvFile) => {
									return (
										<ListItem key={`file-export-${csvFile.name}`}>
											{/* <ListItemAvatar>
											<Avatar>
												<DownloadIcon />
											</Avatar>
										</ListItemAvatar> */}
											<ListItemText primary={`${csvFile.name}.csv`} />
											<ListItemSecondaryAction>
												<FileLink csvFile={csvFile} />
											</ListItemSecondaryAction>
										</ListItem>
									);
								})}
							</List>
						)}
						{loading && <CircularProgress />}
					</Box>
					<Box className={classes.actions}>
						<Button onClick={closeModal} color="secondary">
							{t('common:cancel')}
						</Button>
						<Button onClick={confirmExport} color="primary" disabled={loading || csvFiles.length > 0}>
							{t('common:ok')}
						</Button>
					</Box>
				</PaperContainer>
			</Modal>
		</FullLayout>
	);
}

const useStyles = makeStyles((theme) =>
	createStyles({
		searchContainer: {
			padding: theme.spacing(3),
		},
		button: {
			maxWidth: '200px',
			marginTop: theme.spacing(1),
		},
		modal: {
			position: 'absolute',
			width: '100%',
			height: 350,
			maxWidth: '600px',
			margin: 'auto',
			top: 0,
			bottom: 0,
			left: 0,
			right: 0,
		},
		modalChild: {
			padding: theme.spacing(2),
			height: 250,
			overflow: 'auto',
		},
		actions: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
		},
		schoolLabelContainer: {
			marginTop: theme.spacing(1),
		},
		schoolLabel: {
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			padding: theme.spacing(0.5),
			paddingLeft: theme.spacing(1),
		},
	})
);

// Function to download data to a file
function FileLink({ csvFile: { file: data, name: filename } }: { csvFile: ExportFile }) {
	var file = new Blob([data], { type: 'text/csv' });
	return (
		<a href={URL.createObjectURL(file)} download={filename}>
			<DownloadIcon />
		</a>
	);
}

function isValidStatus(status: any): status is ListStatus {
	return typeof status === 'string' && listStatus.includes(status as ListStatus);
}
