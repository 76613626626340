import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import ListsList from 'components/ListsList';

import ScreenContainer from 'components/containers/ScreenContainer';
import CreateIcon from 'components/icons/CreateIcon';

function Lists() {
	const { t } = useTranslation();

	const history = useHistory();

	return (
		<ScreenContainer title={t('general:list_plural')}>
			<CreateIcon onCreate={() => history.push('/lists/new')} />

			<ListsList />
		</ScreenContainer>
	);
}

export default Lists;
