import { useMemo } from 'react';

function useInDev() {
	const _inDEV = !!process && !!process.env.NODE_ENV && process.env.NODE_ENV === 'development';

	const inDev = useMemo(() => _inDEV, [_inDEV]);

	return inDev;
}

export default useInDev;
