import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	Box,
	Card,
	CardActions,
	CardContent,
	CardMedia,
	createStyles,
	IconButton,
	Theme,
	Typography,
	makeStyles,
	Modal,
	FormGroup,
	TextField,
	Button,
} from '@material-ui/core';

import ShareIcon from '@material-ui/icons/Share';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';

import defaultImage from 'assets/images/internet.svg';
import ConfirmationDialog from 'components/ConfirmationDialog';
import InputContainer from 'components/containers/InputContainer';
import CustomTooltip from 'components/tooltips/CustomTooltip';
import SelectInput from './SelectInput';
import { ShowroomMaterial } from 'teachers-types';

type Props = {
	item: Item;
	disabled: boolean;
	onUpdate: (item: Item) => any;
};

const defaultNewItem: ShowroomMaterial = { title: '', thumbnail: '', url: '', type: 'pdf' };

function ShowroomInput({ item, onUpdate, disabled }: Props) {
	const { t } = useTranslation();

	const classes = useStyles();

	const [showAddItem, setShowAddItem] = useState(false);
	const [showDeleteWarning, setShowDeleteWarning] = useState<ShowroomMaterial | null>(null);
	const [newItem, setNewItem] = useState<ShowroomMaterial>(defaultNewItem);

	const getOnUpdate = useCallback((key: keyof ShowroomMaterial | 'thumbnail') => {
		return (value: string) => {
			setNewItem((current) => {
				return { ...current, [key]: value };
			});
		};
	}, []);

	const updateTitle = useMemo(() => getOnUpdate('title'), [getOnUpdate]);
	const updateUrl = useMemo(() => getOnUpdate('url'), [getOnUpdate]);
	const updateType = useMemo(() => getOnUpdate('type'), [getOnUpdate]);
	const updateThumbnail = useMemo(() => getOnUpdate('thumbnail'), [getOnUpdate]);

	const _onUpdate = useCallback(
		(updatedItem: Item) => {
			onUpdate(updatedItem);
			setShowAddItem(false);
			setShowDeleteWarning(null);
			setNewItem(defaultNewItem);
		},
		[onUpdate]
	);

	return (
		<InputContainer fullWidth={true} title={t('item:showroom')}>
			<Box className={classes.showroomAddContainer}>
				<CustomTooltip
					aria-label={t('common:add')}
					title={t('common:add')}
					disabled={disabled}
					onClick={() => setShowAddItem(true)}
					icon={AddIcon}
				/>
			</Box>
			<Box className={classes.showroomContainer}>
				{item.showroom.map((showroomItem) => {
					return (
						<Card className={classes.cardRoot}>
							<CardMedia
								className={classes.cardMedia}
								image={showroomItem.type === 'pdf' ? showroomItem.thumbnail : defaultImage}
							/>

							<CardContent>
								<Typography variant="body2" color="textSecondary" component="p">
									{showroomItem.title}
								</Typography>
							</CardContent>
							<CardActions className={classes.cardActions}>
								<IconButton aria-label="share" className={classes.cardAction} style={{ marginTop: '6px' }}>
									<a href={showroomItem.url} target="_blank" rel="noreferrer">
										<ShareIcon />
									</a>
								</IconButton>
								<IconButton
									aria-label="delete"
									onClick={() => setShowDeleteWarning(showroomItem)}
									className={classes.cardAction}
								>
									<DeleteIcon />
								</IconButton>
							</CardActions>
						</Card>
					);
				})}
			</Box>

			{!!showDeleteWarning && (
				<ConfirmationDialog
					title={t('items:deleteShowroomItemTitle')}
					description={t('items:deleteShowroomItemDescription')}
					onClose={(confirmed) =>
						confirmed ? _onUpdate(removeShowroomItemFromItem(item, showDeleteWarning)) : setShowDeleteWarning(null)
					}
				/>
			)}

			<Modal onClose={() => setShowAddItem(false)} open={showAddItem}>
				<FormGroup className={classes.form}>
					<SelectInput
						filterKey="showroomType"
						value={newItem.type || 'pdf'}
						setFilter={(ev) => updateType(ev.currentTarget.value as string)}
					/>

					<TextField
						id="title"
						label={t('item:title')}
						fullWidth={true}
						value={newItem.title}
						variant="outlined"
						className={classes.input}
						onChange={(ev) => updateTitle(ev.currentTarget.value)}
					/>

					<TextField
						id="url"
						label={t('common:url')}
						fullWidth={true}
						value={newItem.url}
						variant="outlined"
						className={classes.input}
						onChange={(ev) => updateUrl(ev.currentTarget.value)}
					/>

					{newItem.type === 'pdf' && (
						<TextField
							id="thumbnail"
							label={t('common:thumbnail')}
							fullWidth={true}
							value={newItem.thumbnail}
							variant="outlined"
							className={classes.input}
							onChange={(ev) => updateThumbnail(ev.currentTarget.value)}
						/>
					)}

					<Button
						variant="contained"
						color="primary"
						size="large"
						className={classes.button}
						startIcon={<SaveIcon />}
						onClick={() => _onUpdate(addShowroomItemToItem(item, newItem))}
						fullWidth={true}
						disabled={!newItem.title || !newItem.url || (newItem.type === 'pdf' && !newItem.thumbnail)}
					>
						{t('common:save')}
					</Button>
				</FormGroup>
			</Modal>
		</InputContainer>
	);
}

const useStyles = makeStyles((theme: Theme) =>
	createStyles({
		showroomAddContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-end',
			paddingRight: theme.spacing(1),
		},
		showroomContainer: {
			display: 'flex',
			flexDirection: 'row',
			justifyContent: 'flex-start',
			gap: theme.spacing(2),
			flexWrap: 'wrap',
		},
		showroomItemContainer: {
			borderWidth: '2px',
			borderColor: theme.palette.divider,
			borderRadius: theme.spacing(1),
			borderStyle: 'solid',
			padding: '4px',
			width: '100px',
			height: '55px',
			objectFit: 'contain',
		},
		cardRoot: {
			width: 150,
		},
		cardMedia: {
			height: 0,
			paddingTop: '56.25%', // 16:9
		},
		cardActions: {
			display: 'flex',
			justifyContent: 'space-evenly',
			alignItems: 'center',
		},
		cardAction: {
			width: 25,
			height: 25,
		},
		form: {
			display: 'flex',
			flexDirection: 'row',
			gap: theme.spacing(2),
			backgroundColor: theme.palette.background.paper,
			margin: theme.spacing(2),
			padding: theme.spacing(2),
		},
		input: {
			// flexGrow: 1,
			marginBottom: theme.spacing(2),
			maxWidth: '400px',
		},
		button: {
			marginTop: theme.spacing(2),
			marginBottom: theme.spacing(1),
		},
	})
);

export default ShowroomInput;

function addShowroomItemToItem(item: Item, showroomItem: ShowroomMaterial) {
	try {
		const showroom = [...item.showroom, showroomItem];
		const data: Item = { ...item, showroom };
		return data;
	} catch (error) {
		console.warn(error);
	}
	return item;
}

function removeShowroomItemFromItem(item: Item, showroomItem: ShowroomMaterial) {
	try {
		const showroom = item.showroom.filter((current) => current.url !== showroomItem.url);
		const data: Item = { ...item, showroom };
		return data;
	} catch (error) {
		console.warn(error);
	}
	return item;
}
