import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme, ListItemText, useMediaQuery, TableHead, TableRow, TableCell } from '@material-ui/core';
import { createStyles, makeStyles } from '@material-ui/core/styles';

import { sanitizedInput, sort } from 'lib/helpers';

import SearchToolbar from 'components/SearchToolbar';
import ResponsiveTable from 'components/ResponsiveTable';
import FullLayout from 'components/layouts/FullLayout';

import { rowsPerPageOptions } from 'config/constants';
import { CustomTooltipProps } from 'components/tooltips/CustomTooltip/CustomTooltip';

type Props = {
	loading?: boolean;
	onSelect?(school: School): any;
	schools: School[];
	placeholder?: string;
	customAction?: CustomTooltipProps;
};

export default function SchoolTable(props: Props) {
	const { t } = useTranslation();

	const classes = useStyles();

	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('md'));

	const [search, setSearch] = useState('');
	const [page, setPage] = React.useState(0);
	const [rowsPerPage, setRowsPerPage] = React.useState(25);

	const handleChangePage = (_: unknown, newPage: number) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const searchValue = sanitizedInput(search);

	const schools = props.schools.filter((school) => {
		return (
			!searchValue ||
			sanitizedInput(school.name).includes(searchValue) ||
			!!school.levels.find((level) => sanitizedInput(level).includes(searchValue))
		);
	});

	return (
		<FullLayout>
			<SearchToolbar
				onSearch={(value) => setSearch(value)}
				live={true}
				placeholder={props.placeholder}
				customAction={props.customAction}
			/>

			<ResponsiveTable
				elements={isMobile ? schools : schools.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)}
				list={{
					primaryKey: '_id',
					renderListItemText: (school) => {
						const onClickProps = !!props.onSelect ? { onClick: () => props.onSelect!(school) } : {};
						return <ListItemText {...onClickProps} primary={`${school.name}`} secondary={school.levels.join(' - ')} />;
					},
				}}
				table={{
					renderHead: () => (
						<TableHead>
							<TableRow>
								<TableCell align="left" padding="normal">
									{t('common:name')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:province')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('common:levels')}
								</TableCell>
								<TableCell align="left" padding="normal">
									{t('items:curriculum')}
								</TableCell>
							</TableRow>
						</TableHead>
					),
					renderRow: (school) => {
						const onClickProps = !!props.onSelect ? { onClick: () => props.onSelect!(school) } : {};
						return (
							<TableRow hover {...onClickProps} tabIndex={-1} key={school._id} className={classes.row}>
								<TableCell>{school.name}</TableCell>
								<TableCell>{school.province}</TableCell>
								<TableCell>{school.levels?.sort(sort()).join(', ')}</TableCell>
								<TableCell>{school.curriculum?.sort(sort()).join(', ')}</TableCell>
							</TableRow>
						);
					},
					pagination: {
						count: schools.length,
						page,
						rowsPerPage,
						rowsPerPageOptions,
						onPageChange: handleChangePage,
						onRowsPerPageChange: handleChangeRowsPerPage,
					},
				}}
			/>
		</FullLayout>
	);
}

const useStyles = makeStyles(() =>
	createStyles({
		row: {
			cursor: 'pointer',
		},
	})
);
