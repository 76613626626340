import { useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { AppDispatch } from 'store/Store';

import { removeSession } from 'lib/storage';

function useSetTokenExpired() {
	const dispatch = useDispatch<AppDispatch>();

	const setExpired = useCallback(() => {
		dispatch({ type: 'tokenExpired' });
		removeSession();
	}, [dispatch]);

	return setExpired;
}

export default useSetTokenExpired;
