import { useSelector } from 'react-redux';
import { RootState } from 'store/reducers';

function useServerDown() {
	const { serverDown } = useSelector(({ session: { serverDown } }: RootState) => ({
		serverDown,
	}));

	return serverDown;
}

export default useServerDown;
