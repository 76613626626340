import useServerDown from 'hooks/useServerDown';
import { PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ServerDown.module.css';

function ServerDown({ children }: PropsWithChildren<{}>) {
	const { t } = useTranslation();
	const serverIsDown = useServerDown();

	return (
		<>
			{serverIsDown && <div className={styles.downContainer}>{t('common:serverIsDown')}</div>}
			{children}
		</>
	);
}

export default ServerDown;
