import React, { useCallback } from 'react';

import { Route, Redirect } from 'react-router-dom';
import { validateComponent } from 'lib/session';
import { getDataFromString, isServerRules } from 'lib/helpers';

import useLoggedAndRules from 'hooks/useLoggedWithRules';

const PrivateRoute: React.FunctionComponent<RoutesProps> = ({ component: Component, mode, ...rest }) => {
	const { logged, rulesString } = useLoggedAndRules();

	const validRules = useCallback(() => isServerRules(rest.path), [rest.path]);

	const rules = getDataFromString<ServerRules>(rulesString, validRules());

	return (
		<Route
			{...rest}
			render={(props) => {
				return !!logged ? (
					validateComponent(rules, { ...props, ...rest }, mode) ? (
						<Component {...props} />
					) : (
						<Redirect to="/unauthorized" />
					)
				) : (
					<Redirect to="/login" />
				);
			}}
		/>
	);
};

export default PrivateRoute;
